#tpl_b{
	.list-slider-box{
		position:relative;
		width:100%;
		height:224px;
		margin:0 auto 20px;		
		.postLst, .list-slider-warpper{
			height:224px;
			overflow:hidden;

			.list-slider{
	
			}
			
			.box-post-list{
				width:198px;
				margin:0px 8px 0px;
				@include respond-to(res_lv1){
					width:48%;
				}
				@include respond-to(res_lv2){
					width:48%;		
				}
				@include respond-to(res_lv3){
			
				}
				@include respond-to(res_lv4){
				
				}
				@include respond-to(res_lv5){
				
				}				
				
				.post-list-cover{
					
				}	
			} 
		}
	
		
		
		.btn-list-slider{
			position:absolute;
			top:49px;
			padding-top:10px;
			border:1px solid #ccc;
			border-radius: 50%;
		    height: 40px;
		    width: 40px;
			z-index:99;
			background-color:white;
			color:#ccc;
			cursor:pointer;
			text-align: center;
			display:none;
		    font-weight: bold;
		    font-size: 15px;
			-webkit-box-shadow: 2px 5px 7px -2px rgba(0,0,0,0.75);
			-moz-box-shadow: 2px 5px 7px -2px rgba(0,0,0,0.75);
			box-shadow: 2px 5px 7px -2px rgba(0,0,0,0.75);
			/*
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
			filter: alpha(opacity=75);
			-moz-opacity: 0.75;
			-khtml-opacity: 0.75;
			opacity: 0.75;
			*/
			.xi{
				font-weight:bold;
			}
		    &:hover{
				background-color:#f1f1f1;
		    }
		}
		.btn-list-slider-prev{
			left:-20px;
			@include respond-to(res_lv1){
				left:0px;
			}
			@include respond-to(res_lv2){
				left:0px;
			}			
		}
		.btn-list-slider-next{
			right:-20px;
			@include respond-to(res_lv1){
				right:0px;
			}
			@include respond-to(res_lv2){
				right:0px;
			}						
		}		
	}
	
	 
}
