
/*
#main-top{
	padding:25px 0px;
	
	#top-vod{
		position:relative;
		
		@include respond-to(res_lv1){
			
		}
		@include respond-to(res_lv2){
			
		}
		@include respond-to(res_lv3){
			float:left;
			width:65%;
		}
		@include respond-to(res_lv4){
			float:left;
			width:65%;
		}
		@include respond-to(res_lv5){
			float:left;
			width:65%;
		}
		
		.main-top-label{
			padding-bottom:24px;
			font-size:20px;
			font-weight:bold;
		}
		#slide-control-bar{
			width:150px;
			margin:20px auto 0;
			.btn-divider{
				color:#d6d6d6;
			}
			.btn-choose{
				
			}
			.btn-choose.selected{
	
			}			
		}
	}
	#vod-more{
	
		.btn-zone{
			padding-bottom:25px;
			font-size:19px;
			.btn-divider{
				font-size:13px;
				color:#dbdbdb;
				padding-top:5px;
				
			}			
		}
		@include respond-to(res_lv1){
			margin-top:15px;
		}
		@include respond-to(res_lv2){
			margin-top:15px;
		}
		@include respond-to(res_lv3){
			float:right;
			width:34%;
		}
		@include respond-to(res_lv4){
			float:right;
			width:34%;
		}
		@include respond-to(res_lv5){
			float:right;
			width:34%;
		}

		#slide-posts{
			overflow: hidden;
			@include respond-to(res_lv1){
				height:$slideHeight_lv1 - $btnChooseHeight;
			}
			@include respond-to(res_lv2){
				height:$slideHeight_lv2 - $btnChooseHeight;
			}
			@include respond-to(res_lv3){
				height:$slideHeight_lv3 - $btnChooseHeight;
			}
			@include respond-to(res_lv4){
				height:$slideHeight_lv4 - $btnChooseHeight;
			}
			@include respond-to(res_lv5){
				height:$slideHeight_lv5 - $btnChooseHeight;
			}
			ul{
				li{
					margin-bottom: 15px;
					a{
						display:block;
						position:relative;
						.slide-title{
							margin-top:20px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							-o-text-overflow: ellipsis;
							-ms-text-overflow: ellipsis;							
						}
						.bottomItem{
							position:absolute;
							padding:10px;
						    bottom: 0px;
						    .box-divider{
								color: #a9a9a9;
							    padding: 0px 10px;
							    font-size: 12px;							    
						    }
							.user-profile-image{
								float:left;
							}
							.user-profile-name{
								margin-top:5px;
								white-space: nowrap;
							    text-overflow: ellipsis;
							    -o-text-overflow: ellipsis;
							    -ms-text-overflow: ellipsis;
							    overflow: hidden;
							    float: right;
							    width: 80%;
							}
							.box-created{
								color: #a9a9a9;
								font-weight: 100;
								font-size: 12px;								
							}	    	
						}						
						
					}
				}
			}	
			.scrollbar-inner{
			@include respond-to(res_lv1){
				height:$slideHeight_lv1 - $btnChooseHeight;
			}
			@include respond-to(res_lv2){
				height:$slideHeight_lv2 - $btnChooseHeight;
			}
			@include respond-to(res_lv3){
				height:$slideHeight_lv3 - $btnChooseHeight;
			}
			@include respond-to(res_lv4){
				height:$slideHeight_lv4 - $btnChooseHeight;
			}
			@include respond-to(res_lv5){
				height:$slideHeight_lv5 - $btnChooseHeight;
			}
					ul{
						height:($slidePostsHeight+15)*5;	
					}

			}
			.slide-post{
				@include respond-to(res_lv1){
					float:left;
				}
				@include respond-to(res_lv2){
					float:left;
				}
				@include respond-to(res_lv3){
				}
				@include respond-to(res_lv4){
				}
				@include respond-to(res_lv5){
				}
			}		
	
			.slide-post-cover{
				background-size: cover;
				height: $slidePostsHeight;
				background-position: 50%;							
				float:left;
				width:39%;				
				@include respond-to(res_lv1){
					
				}
				@include respond-to(res_lv2){
		
				}
				@include respond-to(res_lv3){
				}
				@include respond-to(res_lv4){
				}
				@include respond-to(res_lv5){
					height: $slidePostsHeight;
				}		
			}
		}
	}
	.slide{
		.post-list-cover-image{
			@include respond-to(res_lv1){
				height:$slideHeight_lv1;
			}
			@include respond-to(res_lv2){
				height:$slideHeight_lv2;
			}
			@include respond-to(res_lv3){
				height:$slideHeight_lv3;
			}
			@include respond-to(res_lv4){
				height:$slideHeight_lv4;
			}
			@include respond-to(res_lv5){
				height:$slideHeight_lv5;
			}
		}
	}
	
}
*/