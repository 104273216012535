#univ-page-navi{
	margin:20px auto 0;
	.navi {
		a{
			text-align: center;			
			font-size:18px;
			padding-bottom:2px;
			&.selected{
				color:#0aa958;
				border-bottom:2px solid #0aa958;
			}
			/*
			@include respond-to(res_lv1){
				font-size:15px;
			}
			@include respond-to(res_lv2){
				font-size:15px;
			}
			@include respond-to(res_lv3){		
				font-size:16px;
			}
			@include respond-to(res_lv4){
				font-size:18px;
			}
			@include respond-to(res_lv5){
				font-size:18px;	 		
			}			
			*/
		}
	}	
	
}