.wide{
	  @include respond-to(res_lv1) { display:none; }
	  @include respond-to(res_lv2) { }
	  @include respond-to(res_lv3) { }
}
.narrow{
	  @include respond-to(res_lv1) {  }
	  @include respond-to(res_lv2) { display:none; }
	  @include respond-to(res_lv3) { display:none;  }
	  @include respond-to(res_lv4) { display:none;  }
	  @include respond-to(res_lv5) { display:none;  }	  	  
}
.responsive-hide{

	&.lv1{
		@include respond-to(res_lv1) { display:none; }		
	}
	&.lv2{
		@include respond-to(res_lv2) { display:none; }		
	}
	&.lv3{
		@include respond-to(res_lv3) { display:none;  }	
	}
	&.lv4{
		@include respond-to(res_lv4) { display:none;  }		
	}
	&.lv5{
		@include respond-to(res_lv5) { display:none;  }			
	}
}

.tab-panel{
	display:none;
	&.selected{
		display:block;
	}
}

.box-pagination{
	text-align: center; 
}

#container{
	min-height: 100%;
    padding-bottom:$footerHeight + 30;
    background-color: #f4f4f4;
    position: relative;
    
	@include respond-to(res_lv1){
		padding-top:$headerNarrowHeight;
		margin-left:0px !important;
		padding-bottom:$footerHeight_MAX + 30;
	}
	@include respond-to(res_lv2){
		padding-top:$headerWideHeight;
		padding-bottom:$footerHeight_MAX + 30;
	}
	@include respond-to(res_lv3){
		padding-top:$headerWideHeight;
		padding-bottom:$footerHeight_MAX + 30;
	}
	@include respond-to(res_lv4){
		padding-top:$headerWideHeight; 
	}
	@include respond-to(res_lv5){
		padding-top:$headerWideHeight; 
	}	
}

#container.two-rows{ 
	@include respond-to(res_lv1) {  }
	@include respond-to(res_lv2) { margin:0 auto 0; }
	@include respond-to(res_lv3) { margin:0 auto 0; }

	#center-container{
		margin-top:$headerWideHeight;
		  @include respond-to(res_lv2) { 
			  width:850px;			  
			  margin: $headerWideHeight auto 0;
			}
		  @include respond-to(res_lv3) { 
			  width:1200px;
			  margin: $headerWideHeight auto 0;
	    }
	}
}

.wrapper{
	width:100%;
	min-height:190px;
	  @include respond-to(res_lv1) {  }
	  @include respond-to(res_lv2) { }
	  @include respond-to(res_lv3) { }
}
.inner{
	margin: 0 auto 0;
	position:relative;
	@include respond-to(res_lv1) { 
		width:100%;
		
	}
	@include respond-to(res_lv2) { 
		max-width:$responsive-lv1;
	}
	@include respond-to(res_lv3) {
		//padding: 0 30px 0px;
		max-width:$responsive-lv2;
		
	}
	@include respond-to(res_lv4) {
		padding:0 $inner-padding-v3 0px;
		max-width:$responsive-lv3;
		
	}
	@include respond-to(res_lv5) {
		padding: 0 $inner-padding-v4 0px;
		max-width:$responsive-lv4;
	}
}

.dropdown-menu{
	padding:0px;
	margin:0px;
	min-width:100px;
}

.openContentModal{
		cursor:pointer;
		color:#5190d7;
}

html, body{
	height:100%;

}
#body-container{
	min-height:100%;
	position:relative;
}
/* OverWrite Bootstrap */
body, h1, h2, h3, h4, input, button {
    //font-family: Dotum,'돋움',Helvetica,AppleSDGothicNeo,sans-serif;
    //font-family: 'Spoqa Han Sans', 'Spoqa Han Sans JP', Dotum,'돋움',Helvetica,"Apple SD Gothic Neo", 'Sans-serif';
    font-family: NanumSquare, "Apple SD Neo Gothic", "malgun gothic", dotum, sans-serif;
    //font-family: "segoe ui","malgun gothic","microsoft neogothic",applegothic,sans-serif;
    //font-family: Dotum,'돋움',Helvetica,"Apple SD Gothic Neo",sans-serif;
}
ul{margin:0px;}
p{margin:0px;}
.row{margin:0px;}
.modal_content{padding:8px;}
#media_up{margin-top:15px;}
.pagination{margin:8px;}
pre{padding:0px;margin:0px;border:0px;background-color:transparent;}
.btn{}
img {-ms-interpolation-mode:bicubic;} 
/* Common */
body{    background-color: #f4f4f4 !important;}
*{margin:0px;padding:0px;}
.cr, .clear{clear:both;}

textarea{resize: none;}

a{text-decoration: none !important; cursor:pointer; color:#3f3f3f;}
a:hover{text-decoration: none !important; color:#1c1c1c;}
a:active{text-decoration: none !important; }
a.selected{text-decoration: none !important;}

.hidden{visibility: hidden;}
.none{display:none;}
.none.selected{display:block;}
ul li{display:block;}
.fl, .leftBox{float:left;}
.fr, .rightBox{float:right;}
.mr{margin-right:8px;}
.mr-16{margin-right:16px;}
.mr-20{margin-right:20px;}
.mr-32{margin-right:32px;}

.ml{margin-left:8px;}
.ml-16{margin-left:16px;}
.ml-20{margin-right:20px;}
.ml-32{margin-left:32px;}

.mt{margin-top:8px;}
.mt-16{margin-top:16px;}
.mt-24{margin-top:24px;}
.mt-32{margin-top:32px;}

.mb{margin-bottom:8px;}
.mb-16{margin-bottom:16px;}
.mb-24{margin-bottom:24px;}
.mb-32{margin-bottom:32px;}

.centerBox{margin:0px auto;text-align: center;}
.centerText{text-align:center;}
.centerTextBox{text-align: center;}
.prevImgBox{width:250px;height:250px;background-position: 50% 50%;background-size: cover;background-repeat: no-repeat;border:1px solid #ccc;}
.errorMsg{color:red;}
.img-circle{border-radius: 50%;}
.circle{border-radius: 50%;}
.xeicon{font-family: xeicon;}

.explain{font-size:12px;}

.alignCenter{text-align: center;}

$borderColor:#e2e2e2;
.border-top{ border-top:1px solid $borderColor;}
.border-bottom{ border-bottom:1px solid $borderColor;}

.lst > ul > li{float:left;margin-left:10px;}
.lst ul li:first-child{margin-left:0px;}
.btnLst ul li{
	border-top:1px solid #ccc;
	border-right:1px solid #ccc;
	cursor:pointer;
	padding:8px;
	margin:0px;
}
.btnLst ul li.selected{
	background-color:#474747;
	color:white;
}
.btnLst ul li:first-child{
	border-left:1px solid #ccc;
}
#uiBlock {
    background-color: black;
    display: none;
    -moz-opacity: 0.75;
    -khtml-opacity: 0.75;
    opacity: 0.75;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha"(Opacity=75);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=75);
    filter: alpha(opacity=75);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    -webkit-transition: all 2s;
       -moz-transition: all 2s;
        -ms-transition: all 2s;
         -o-transition: all 2s;
            transition: all 2s;	    
}




#dismiss {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
	display: none;
    -webkit-transition: all 2s ;
       -moz-transition: all 2s ;
        -ms-transition: all 2s ;
         -o-transition: all 2s ;
            transition: all 2s ;	
}
#dismiss.leftToggled{left:260px;top:0px;}

#uiBlock.toggled-left-sidebar{
	display:block;	
}
#dismiss.toggled-left-sidebar{
	display:block;	
}

#uiBlock.toggled-search-bar{
	display:block;
}
#dismiss.toggled-search-bar{
	display:block;
}

#uiBlock.toggled-right-sidebar{
	display:block;	
}
#dismiss.toggled-right-sidebar{
	display:block;	
}

#uiBlock.mode-cinema{
	display:block;	
}
#dismiss.mode-cinema{
	display:block;	
}



/* Common Ad Zone */
#mobile_bottom_ad_wrapper{display:none;position:fixed;vertical-align: bottom; left:0px; bottom:0px; width:100%;height:50px;background-color:white;z-index: 2147483647;}
#mobile_bottom_ad{display:none;width:320px; height:50px; z-index:999;margin:0 auto 0;}


#socialLogin{width:290px;margin:0 auto 0;}



/* ====================== Navi ====================== */

.childNavi{display:none;}
.parentNavi{cursor:pointer;}

/* ====================== Header ====================== */
.header-search-bar{ 
	top:0px;
	position:fixed;
	display:none;
	z-index: 1000;
  background-color: $themeBaseColor;	
  #btn_goSearch{
		cursor:pointer;
	}
  input, span{
		border:0px;
	}   
}


/* ====================== Content Containers ====================== */

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chLang{
	padding:5px;
	cursor:pointer;
}

/* Utils */

.tagBox ul li{
	border:1px solid #ccc;
	border-radius: 6px;
}

.tagRemove{
	padding: 5px 6px 3px 6px;	
	border-right: 1px solid #ccc;
 i{
    padding: 3px 10px;
		color:#de747e;
    cursor: pointer;
    font-size: 20px;
  }
}

.tagLabel{
    padding: 3px 10px;
    font-size: 14px;    
    text-align: center;
}


/*  Boxes */
.box-empty{
	padding:30px;
	text-align: center;
	font-size:20px;
}

.facebook{
	background-color:#3c5a98;
}
.twitter{
	background-color:#55acef;
}
.google{
	background-color:#de4b39;
} 

.progress{
	padding:0px;
}