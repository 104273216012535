#tpl_b{
	#header{
		
		
		.wide{
			
			height:$headerWideHeight;
			.utils{

				.btn-show-login{
					margin-top:18px;
					>a{
						margin-top:4px;						
						font-size:12px;
						padding:8px 18px;
						border:1px solid #a7a7a7;
					}
		
					.btn-auth-login{
					    padding: 7px 13px;
					    border: 1px solid #669dcc;
					    font-size: 13px;
					    cursor: pointer;
					    margin-top: -3px;
					}		
					
				}
			
			}
			
			#btn-master-category{
				float:none;
				margin:0px;
				background-color:white;
				height:52px;
				.btn-navi{
					padding:15px 20px;
					color:black;
					font-size:16px;
					&:hover{
						background-color:#e3e3e3;
						color:#378cd5;					
					}
				}
				.left-content{
					.box{
						//margin:9px;
					}	
				} 
				#btn-zone-header{
					ul{
						padding:16px;
						li{
							float:left;
							font-size:14px;
							color:black;
							padding:2px 10px;
							border-right:1px solid black;
							&:last-child{
								border-right:0px;
							}
						}
					}
				}
			}
			#wide-header-logo{
				width:161px;
				height: 53px;
				margin-top: 11px;
			}
			.header-left{
				float:left;
			}
			#header-search-wrapper{
				padding-top:12px;
				#header-search-box{
					width:90%;
					@include respond-to(res_lv1){
			
					}
					@include respond-to(res_lv2){
						width:80%;
					}
					@include respond-to(res_lv3){
						
					}
					@include respond-to(res_lv4){

					}
					@include respond-to(res_lv5){
					
					}					
					
				}
				#search-keyword{
					margin: 0 auto 0;
					border:0px;
				}
				.header-search-group{
					width:550px;
					margin:0 auto 0;
					@include respond-to(res_lv1){
			
					}
					@include respond-to(res_lv2){
						width:290px;
					}
					@include respond-to(res_lv3){
						width:390px;
					}
					@include respond-to(res_lv4){
						width:450px;
					}
					@include respond-to(res_lv5){
					
					}						
					
					
				}
				.btn-action-search{
				    color: #5c86bc;
				    background-color: white;
				    border:0px;
				    padding:9px 12px;
				}
			}
		}// .wide
		.narrow{
			.btn-left{
				margin-top:10px;
			}
			.utils{

				.btn-show-login{
							
					.btn-auth-login{
					    font-size: 13px;
					    cursor:pointer;
					}		
					
				}
			
			}
		}		
	}// #header

	
}