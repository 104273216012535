
$slideHeight_lv1:170px;
$slideHeight_lv2:247.8px;
$slideHeight_lv3:285.6px;
$slideHeight_lv4:337.4px;
$slideHeight_lv5:420px;

$btnChooseHeight:0px;

$slidePostsHeight:94px;

/* slider container */
.iosslider {
	/* required */
	position: relative;
	top: 0;
	left: 0;
	overflow: hidden;
	
	width: 100%;
	@include respond-to(res_lv1){
		height:$slideHeight_lv1;
	}
	@include respond-to(res_lv2){
		height:$slideHeight_lv2;
	}
	@include respond-to(res_lv3){
		height:$slideHeight_lv3;
	}
	@include respond-to(res_lv4){
		height:$slideHeight_lv4;
	}
	@include respond-to(res_lv5){
		height:$slideHeight_lv5;		
	}
				
}

/* slider */
.iosslider .slider {
	/* required */
	width: 100%;
	height: 100%;
}

/* slide */
.iosslider .slider .slide {
	/* required */
	float: left;
	width: 100%;

	@include respond-to(res_lv1){
		height:$slideHeight_lv1;
	}
	@include respond-to(res_lv2){
		height:$slideHeight_lv2;
	}
	@include respond-to(res_lv3){
		height:$slideHeight_lv3;
	}
	@include respond-to(res_lv4){
		height:$slideHeight_lv4;
	}
	@include respond-to(res_lv5){
		height:$slideHeight_lv5;	
	}
}	
#tpl_b{
	#main-top{
		background-color:#475266;
		padding:0px;
		position:relative;
	}
	.slider-navi-btns{
		position:absolute;
	    color: white;
	    background-color: black;	
	    cursor:pointer;	 
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
			filter: alpha(opacity=65);
			-moz-opacity: 0.65;
			-khtml-opacity: 0.65;
			opacity: 0.65;
	    
		@include respond-to(res_lv1){
		    width: 30px;
		    padding:10px 8px;
		    font-size:20px;
		    top: 35%;	
		}
		@include respond-to(res_lv2){
		    width: 40px;
		    padding:10px 8px;
		    font-size:25px;
		    top: 40%;			
		}
		@include respond-to(res_lv3){
		    width: 50px;
		    padding:10px 8px;
		    font-size:35px;
		    top: 40%;			
		}
		@include respond-to(res_lv4){
		    width: 56px;
		    padding:10px 8px;
		    font-size:40px;
		    top: 40%;	
		}
		@include respond-to(res_lv5){
		    width: 66px;
		    padding:10px 8px;
		    font-size:45px;
		    top: 40%;		
		}		    
	    
	}
	
	#slider-prev{
	    left: 0px;
	}
	#slider-next{
	    right: 0px;
	}
	.slide-link{
		position:absolute;
		text-align: center;

		@include respond-to(res_lv1){
			font-size:45px;
			top:30%;		
			left:44%;
		}
		@include respond-to(res_lv2){
			font-size:50px;
			top:40%;		
			left:47%;
		}
		@include respond-to(res_lv3){
			font-size:55px;
			top:40%;		
			left:48%;
		}
		@include respond-to(res_lv4){
			font-size:65px;
			top:40%;		
			left:48%;
		}
		@include respond-to(res_lv5){
			font-size:65px;
			top:40%;		
			left:48%;	
		}

		a{
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
			filter: alpha(opacity=65);
			-moz-opacity: 0.65;
			-khtml-opacity: 0.65;
			opacity: 0.65;
			color:white;
			&:hover{
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
				filter: alpha(opacity=100);
				-moz-opacity: 1;
				-khtml-opacity: 1;
				opacity: 1;				
				color:white;
			}
			
		}
	}	
	/*
	.slide-ui{
		position:absolute;
		top:0px;
		left:0px;
		text-align: center;
		display:none;
		width:100%;
		height:100%;
		.slider-navi-btns{
			position:fixed;
		    color: white;
		    background-color: black;	
		    cursor:pointer;	 
		    
		    
			@include respond-to(res_lv1){
			    width: 30px;
			    padding:10px 8px;
			    font-size:20px;
			    top: 35%;	
			}
			@include respond-to(res_lv2){
			    width: 40px;
			    padding:10px 8px;
			    font-size:25px;
			    top: 40%;			
			}
			@include respond-to(res_lv3){
			    width: 50px;
			    padding:10px 8px;
			    font-size:35px;
			    top: 40%;			
			}
			@include respond-to(res_lv4){
			    width: 56px;
			    padding:10px 8px;
			    font-size:40px;
			    top: 40%;	
			}
			@include respond-to(res_lv5){
			    width: 66px;
			    padding:10px 8px;
			    font-size:45px;
			    top: 40%;		
			}		    
		    
		}
		
		#slider-prev{
		    left: 0px;
		}
		#slider-next{
		    right: 0px;
		}
	}
	*/
	#slide-control-bar{
		position:absolute;
		bottom:0px;
		left:0px;
		width:100%;
		background-color:black;
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
		filter: alpha(opacity=65);
		-moz-opacity: 0.65;
		-khtml-opacity: 0.65;
		opacity: 0.65;
		#slide-pager{
			width:100%;			
			text-align: center;
			ul{
				margin:8px auto;
				.item{
					width:25px;
					cursor:pointer;
					float:left;
					text-align: center;
					color:#c0c0c0;
					&.selected{
						color:white;	
					}
				}
				&:after {
				    content: ' ';
				    display: block;
				    clear: both;
				}
			}

					
		}

	}	
}

.slide{
	position:relative;
	.slide-cover-image{
		height:100%;
		background-size:cover;
		background-position: center center;
		/*
		@include respond-to(res_lv1){
			height:$slideHeight_lv1;
		}
		@include respond-to(res_lv2){
			height:$slideHeight_lv2;
		}
		@include respond-to(res_lv3){
			height:$slideHeight_lv3;
		}
		@include respond-to(res_lv4){
			height:$slideHeight_lv4;
		}
		@include respond-to(res_lv5){
			height:$slideHeight_lv5;
		}
		*/
	}
		
	.slide-text{
		position:absolute;
		
		color:white;
		padding:20px;
		font-size:20px;

	    background: none repeat scroll 0 0 #000000;
	    opacity: 0;
	    filter: alpha(opacity=0);
	    position: absolute;
	    text-align: left;		
		
		@include respond-to(res_lv1){
			font-size: 12px;
			padding:8px;
		}
		@include respond-to(res_lv2){
			font-size: 15px;
			padding:10px;			
		}
		@include respond-to(res_lv3){
			font-size: 17px;
			padding:13px;			
		}
		@include respond-to(res_lv4){
			font-size: 19px;
			padding:15px;
		}
		@include respond-to(res_lv5){
			font-size: 22px;
			padding:15px;			
		}		
		
	}
	.slide-title{
		left:10%;
		
		@include respond-to(res_lv1){
			top:10px;
		}
		@include respond-to(res_lv2){
			top:15px;		
		}
		@include respond-to(res_lv3){
			top:20px;			
		}
		@include respond-to(res_lv4){
			top:25px;
		}
		@include respond-to(res_lv5){
			top:30px;			
		}		
	}
	.slide-desc{
		left:10%;
		max-width:45%;
		overflow: hidden;
		
		@include respond-to(res_lv1){
			top:55px;
		}
		@include respond-to(res_lv2){
			top:80px;			
		}
		@include respond-to(res_lv3){
			top:100px;			
		}
		@include respond-to(res_lv4){
			top:120px;
		}
		@include respond-to(res_lv5){
			top:130px;
			max-height: 80px;					
		}			
	}
}
/*
#tpl_b{
	#main-top{
		margin-top:20px;
		background-color:#475266;
		padding:0px;
		position:relative;
		#top-vod{
			position:relative;
			float:none;
			width:100%;
			.slide-ui{
				display:block;
				#slider-prev{
						left:inherit;
						top:inherit;
						right: 120px;
						bottom: 20px;
						z-index: 100;				
					@include respond-to(res_lv1){
						right: 35px;
						bottom: 10px;
					}
					@include respond-to(res_lv2){
						
					}
					@include respond-to(res_lv3){
			
					}
					@include respond-to(res_lv4){
			
					}
					@include respond-to(res_lv5){
			
					}
					a{
						font-size:45px;
					}
					
				}
				#slider-next{
						right:inherit;
						top:inherit;
						right: 60px;
						bottom: 20px;
						z-index: 100;									
					@include respond-to(res_lv1){

					}
					@include respond-to(res_lv2){
						
					}
					@include respond-to(res_lv3){
			
					}
					@include respond-to(res_lv4){
			
					}
					@include respond-to(res_lv5){
			
					}
					
					a{
						font-size:45px; 
					}					
									
				}
			}
		}
		
		#slide-control-bar{
			position:absolute;
			bottom:40px;
			right:155px;
			
			@include respond-to(res_lv1){

			}
			@include respond-to(res_lv2){
				right:inherit;
				width:100%;
			}
			@include respond-to(res_lv3){
				right:10%;
			}
			@include respond-to(res_lv4){
	
			}
			@include respond-to(res_lv5){
				right:235px;
			}			
			
		}
		
		.slide{
			.slide-cover-image{
				width:100%; 
				@include respond-to(res_lv1){
					height:$slideHeight_lv1;
				}
				@include respond-to(res_lv2){
					height:$slideHeight_lv2;
				}
				@include respond-to(res_lv3){
					height:$slideHeight_lv3;
				}
				@include respond-to(res_lv4){
					height:$slideHeight_lv4;
				}
				@include respond-to(res_lv5){
					height:$slideHeight_lv5;
				}
				.post-list-cover-image{
					height:100%;
					background-size:cover;
					background-position: center center;
				}
			}
			.vod-info-box{
				width:35%;
				height:100%;
				position:relative;
				background-color:#475266;
				@include respond-to(res_lv1){
					height:$slideHeight_lv1;
				}
				@include respond-to(res_lv2){
					height:$slideHeight_lv2;
				}
				@include respond-to(res_lv3){
					height:$slideHeight_lv3;
				}
				@include respond-to(res_lv4){
					height:$slideHeight_lv4;
				}
				@include respond-to(res_lv5){
					height:$slideHeight_lv5;
				}				
				.slide-owner{
					position: absolute;
					top: 112px;
					left: 28px;
					a{
						
					}		 
				}			
				
			}
		}
		.bx-wrapper{
			.slide-info{
			    position: absolute;
			    top: 15px;
			    left: 0px;
			    padding-left: 20px;
			    width: 100%;
			    z-index: 1000;				
			}
		}
		
		#slide-pager{
			ul{
				li{
					a{
						font-size:12px;
					}
				}
			}
			.slide-page.active{
				color:white;
			}
		} 
	}
	
}
*/