.block-tooltip{
	color:#b6bbc2;
	cursor:pointer;
	&:hover, .active{
		color:#3dacdb;	
	}
	
}

.tipso_style{
	border-bottom:0px;
}

.tipso_bubble{
	border:1px solid #d2d2d4;
	text-align: left;
	&.default{
		font-size:12px;		
	}
	padding:10px;
	-webkit-box-shadow: 5px 5px 14px 0px rgba(235,235,235,1);
	-moz-box-shadow: 5px 5px 14px 0px rgba(235,235,235,1);
	box-shadow: 5px 5px 14px 0px rgba(235,235,235,1);	
}