
#post-info{
	margin-top:20px;
	.top{
		//padding:10px;
		.post-title{
			font-size:25px;
			float:left;
		}
			
		
	}
	
	.top-sub{
		position:relative;
		border-bottom:1px solid $commonBorderColor;
		margin-bottom:20px;
		.post-cnt-date{
			font-size:14px;
			color:#808080;
			padding-top:7px;
		}
		.post-sparkbars{
		    height: 3px;
		    width: 100%;
		    position: absolute;
		    bottom: 0px;
		    background-color:#ccc;
			#post-sparkbar-1{
				float:left;				
				background-color:#5c89fe;
				height:100%;
			}
			#post-sparkbar-2{
				float:right;				
				background-color:#d55959;				
				height:100%;							
			}
		}		
	}
	
	.middle{
		position:relative;
		border-bottom:1px solid $commonBorderColor;
		padding-bottom:20px;
		.user-info{
			margin-left:10px;
			.subscribe{
				padding:4px 10px;
				margin-top:8px;
				cursor:pointer;
				background-color:#e9e9e9;
				border:1px solid #8d8d8d;
				font-size:12px;
			    width: 75px;
			    text-align: center;				
			}
		}
		
	}
	.bottom{
		padding-top:8px;
		//border-bottom:1px solid $commonBorderColor;
		padding-bottom:20px;
		margin-bottom:20px;
		position:relative;
		#btn_like{
			margin-right: 10px;
			cursor:pointer;
		}
		#btn_dislike{
			cursor:pointer;			
		}
		#post-license{
			position:absolute;
			bottom:20px;
			right:20px;
		}
		.post-desc{
			font-size:13px;
			overflow:hidden;
		}

	}
	
	#bottom-sub{
		
		#box-post-download{
			border-bottom:1px solid $commonBorderColor;
			padding-bottom:20px;
			margin-bottom:20px;			
			.btn-download{ 
				display:block;
				border:1px solid #dbdbdb;
				float:left;
				max-width:48%;
				min-width: 20%;				
				margin-right:1%;
				background-color:white;
				margin-top:8px;
				&:hover{
					background-color:#eaeaea;
				}
				.download-icon{
					padding:8px 0%;
					border-right:1px solid #dbdbdb;
					width:26%;
					text-align: center;
				}
				.download-label{
					width:72%;
					padding:8px 0%;
					padding-left:4px;
				    display:inline-block;
				    white-space: nowrap;
				    overflow:hidden !important;
				    text-overflow: ellipsis;					
				}
			}
			&:after{
				clear:both;
				content:".";
				display:block;
				height:0;
				line-height:0;
				visibility:hidden;
			}
		}
		
		.post-tags{
			.post-tag{
				font-size:12px;
				margin-right:12px;
				display:block;
				float:left;		
			}
			&:after{
				visibility: hidden;
				display: block;
				content: "";
				clear: both;
				height: 0;
			}
			
		}
		
	}
	
	#post-info-count{
	    position: relative;
	    right: 0px;
	    bottom: 0px;
	    height: 30px;
	    font-size: 20px;
	    margin-top: 0px;
	    .post-feeling {
		    padding-left:10px;
	    }
	}	
	
}
.post-share{
	padding:8px;
	width:270px;
	.btn-share{
		color:white;
		font-size:21px;
	    width: 38px;
	    height: 38px;
	    text-align: center;
	    vertical-align: middle;
	    display:block;
	    float:left;
	    cursor:pointer;
	    margin-right:4px;
	    i{
		    padding-top: 10px;	    
	    }
	    img{
		    width:38px;
	    }
	}
    .post-share-label{
	    font-size:14px;
		color:#434343;
		font-weight:bold;
		padding:4px;
    }

}
.post-share-link{
	border-top:1px solid $commonBorderColor;
    margin:8px;
    padding-top:10px;
    input{
	    border:0px;
	    border-bottom:2px solid $commonBorderColor;
	    width:100%;
	    font-size:12px;
	    padding:5px;
	    &:focus{
		    outline: none;
	    }
    }
}

.category-label{
	padding:30px 10px 30px;
	font-size:20px;
	
}

.postLst {
	//padding-top:10px;
	.item{
		margin:1%;
		border:1px solid #ccc;
		background-color:white;
		cursor:pointer;
		padding:0px;
		float:left;
		
		@include respond-to(res_lv1) { width:48%; margin:1%; }
		@include respond-to(res_lv2) { width:32%; }
		@include respond-to(res_lv3) { width:23%; }		
		@include respond-to(res_lv4) { width:23%; }
		@include respond-to(res_lv5) { width:23%; }
		
		.postTitle{
			font-size:15px;
			text-align: center;
			padding:4px;
		}
		.postContent{
			font-size:12px;
			padding:4px;
		}
		img{
			max-width:100%;
		}
		
	}

	.box-post-list{
			@include respond-to(res_lv1){width:100%;margin:0px;margin-bottom:10px;}
			@include respond-to(res_lv2){width:250px;}
			@include respond-to(res_lv3){width:196px;}
			@include respond-to(res_lv4){width:196px;}
			@include respond-to(res_lv5){width:196px;}									
		.previewImg{
			background-size: cover;
			height:110px;
			@include respond-to(res_lv1){width:100%; height:200px;}
			@include respond-to(res_lv2){width:250px;}
			@include respond-to(res_lv3){width:196px;}
			@include respond-to(res_lv4){width:196px;}
			@include respond-to(res_lv5){width:196px;}							
		}
		margin:8px;
	}
	.box-post-list{
		margin:0px 1% 50px;
		//background-color:white;
		cursor:pointer;
		padding:0px;
		float:left;
		height: 224px;
		position:relative;
		//box-shadow: 0 2px 2px rgba(0,0,0,.15);
			@include respond-to(res_lv1){width:48%;margin:1%;margin-bottom:10px;}
			@include respond-to(res_lv2){width:48%;}
			@include respond-to(res_lv3){width:196px;}
			@include respond-to(res_lv4){width:196px;}
			@include respond-to(res_lv5){width:196px;}					
		a:hover{
			color:black;
		}
		.post-list-cover{

		}

		.post-list-cover{
		    display: block;
		    position: absolute;
		    width: 100%;
		    height: 205px;
			.post-list-cover-image{
				background-size: cover;
			    position: absolute;
			    width: 100%;
			    height: 140px;
			    background-position: 50%;
			}
			.post-list-title{
			    position: absolute;
			    font-size: 13px;
			    top: 140px;
			    /* left: 10px; */
			    padding: 8px 0px;
			    width: 100%;
			    height:43px;
			    /*
			    padding-top:20px;
			    padding-bottom:24px;			    
			    */
			    font-weight:bold;
			    overflow: hidden;			
			}
			.post-list-desc{
				position:absolute;
				width:100%;	
				font-size:12px;
				padding:8px;				
				top:163px;
			    white-space: nowrap;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    -o-text-overflow: ellipsis;
			    -ms-text-overflow: ellipsis;				
			}

		}
		
		.bookFirstPost{
			border-top:1px solid #ccc;
			ul{
				li{
					padding:3px;
					font-size:12px;
				}
			}
		}
		.tags{
			position:absolute;
			bottom:45px;
			width:100%;
			padding:4px;
			font-size:12px;
			height:25px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;			
		}
		img{
			max-width:100%;
		}
		.bottomItem{
			position:absolute;
		    bottom: 0px;
		    width: 100%;
		    color: #888;
			.user-profile-image{
				float:left;
			}
			.box-owner-name{
				margin-bottom:2px;
			    a{
				    color:#888;
			    }
			}
			.user-profile-name{
				margin-top:5px;
				white-space: nowrap;
			    text-overflow: ellipsis;
			    -o-text-overflow: ellipsis;
			    -ms-text-overflow: ellipsis;
			    overflow: hidden;
			    float: right;
			    width: 80%;
			}
			.box-meta-info{
				font-size:11px;
			}					    	
		}

		
	}	
}

.right-list {
	margin-top:10px;
	.item{
		border:1px solid #ccc;
		background-color:white;
		cursor:pointer;
		padding:0px;
		float:left;
		
		@include respond-to(res_lv1) { width:48%; margin:1%; }
		@include respond-to(res_lv2) { width:32%; }
		@include respond-to(res_lv3) { width:23%; }
		@include respond-to(res_lv4) { width:23%; }
		@include respond-to(res_lv5) { width:23%; }			
		
		.postTitle{
			font-size:15px;
			text-align: center;
			padding:4px;
		}
		.postContent{
			font-size:12px;
			padding:4px;
		}
		img{
			max-width:100%;
		}
		
	}
	.box-right-list{
		width:100%;
		.previewImg{
			background-size: cover;
			height:110px;
			width:100%;			
		}
		margin:8px;
	}
	.box-right-list{
		margin:0px;
		margin-bottom:15px;
		border:1px solid #ccc;
		background-color:white;
		cursor:pointer;
		padding:0px;
		float:left;
		height: 250px;
		position:relative;
		
	
		a:hover{
			color:black;
		}
		.post-list-cover{

		}

		.post-list-cover{
		    display: block;
		    position: absolute;
		    width: 100%;
		    height: 178px;
			.post-list-cover-image{
				background-size: cover;
			    position: absolute;
			    width: 100%;
			    height: 140px;
			    background-position: 50%;
			}
			.post-list-title{
			    position: absolute;
			    font-size: 15px;
			    top: 140px;
			    /* left: 10px; */
			    padding: 8px;
			    width: 100%;
			    overflow: hidden;
			}
			.post-list-desc{
				position:absolute;
				width:100%;	
				font-size:12px;
				padding:8px;				
				top:166px;
				overflow: hidden;
				 
			    white-space: nowrap;
			   
			    text-overflow: ellipsis;
			    -o-text-overflow: ellipsis;
			    -ms-text-overflow: ellipsis;				
			}

		}
		
		.bookFirstPost{
			border-top:1px solid #ccc;
			ul{
				li{
					padding:3px;
					font-size:12px;
				}
			}
		}
		.tags{
			position:absolute;
			bottom:45px;
			width:100%;
			padding:4px;
			font-size:12px;
			height:25px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;			
		}
		img{
			max-width:100%;
		}
		.bottomItem{
			position:absolute;
			border-top:1px solid #dfdfdf;
		    bottom: 0px;
		    width: 100%;
		    .box-meta-info{
			    font-size:11px;
		    }
		}	
	}
	
}

 .post-list{
	margin-top:10px;
	.item{
		margin:1%;
		border:1px solid #ccc;
		background-color:white;
		cursor:pointer;
		padding:0px;
		float:left;
		
		.postTitle{
			font-size:15px;
			text-align: center;
			padding:4px;
		}
		.postContent{
			font-size:12px;
			padding:4px;
		}
		img{
			max-width:100%;
		}
		
	}
	
	.box-post-list{
		//background-color:white;
		cursor:pointer;
		padding:0px;
		height: 225px;
		position:relative;
		//box-shadow: 0 2px 2px rgba(0,0,0,.15);
		/*
		@include respond-to(res_lv1) { width:48%; margin:1%; }
		@include respond-to(res_lv2) { width:31%; }
		@include respond-to(res_lv3) { width:18%; }	
		@include respond-to(res_lv4){width:18%;}
		@include respond-to(res_lv5){width:18%;}
		*/
		.previewImg{
			background-size: cover;
			height:110px;
			@include respond-to(res_lv1){width:100%; height:200px;}
			@include respond-to(res_lv2){width:250px;}
			@include respond-to(res_lv3){width:196px;}
			@include respond-to(res_lv4){width:196px;}
			@include respond-to(res_lv5){width:196px;}							
		}
		margin:8px;		
				
		a:hover{
			color:black;
		}
		.post-list-cover{

		}

		.post-list-cover{
		    display: block;
		    position: absolute;
		    width: 100%;
		    height: 178px;
			.post-list-cover-image{
				background-size: cover;
			    position: absolute;
			    width: 100%;
			    height: 140px;
			    background-position: 50%;
			}
			.post-list-title{
			    position: absolute;
			    font-size: 15px;
			    top: 140px;
			    /* left: 10px; */
			    padding: 8px 0px;
			    width: 100%;
			    overflow: hidden;
				height:58px;
			}
			.post-list-desc{
				position:absolute;
				width:100%;	
				font-size:12px;
				padding:8px;				
				top:166px;
			    white-space: nowrap;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    -o-text-overflow: ellipsis;
			    -ms-text-overflow: ellipsis;				
			}

		}
		
		.bookFirstPost{
			border-top:1px solid #ccc;
			ul{
				li{
					padding:3px;
					font-size:12px;
				}
			}
		}
		.tags{
			position:absolute;
			bottom:45px;
			width:100%;
			padding:4px;
			font-size:12px;
			height:25px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;			
		}
		img{
			max-width:100%;
		}
		.bottomItem{
			position:absolute;
		    bottom: 0px;
		    width: 100%;
		    color:#888;
		    a{
				color:#888;    
		    }
			.user-profile-image{
				float:left;
			}
			.user-profile-name{
				margin-top:5px;
				white-space: nowrap;
			    text-overflow: ellipsis;
			    -o-text-overflow: ellipsis;
			    -ms-text-overflow: ellipsis;
			    overflow: hidden;
			    float: right;
			    width: 80%;
			}
		    .box-meta-info{
			    font-size:11px;
		    }			
				    	
		}	
	}	
	.box-post-list.no-desc{
		height:225px;
	}
 }