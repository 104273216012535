#container{
	background-color:#f4f4f4;	

	.btn_social i{font-size:30px;}
	
	.left-box{
		float:left;
		@include respond-to(res_lv1){width:100%;}
		@include respond-to(res_lv2){width:100%;}
		@include respond-to(res_lv3){width:800px;}	
	}
	
	.right-box{
		float:left;
		@include respond-to(res_lv1){
			width:100%;
			height:140px;
			overflow:hidden;	
		}
		@include respond-to(res_lv2){
			width:100%;
			height:140px;
			overflow:hidden;
		}
		@include respond-to(res_lv3){width:400px;}				
	}
	

	.box{
		background-color:white;
		//min-height:50px;
	    //box-shadow: 0 1px 2px rgba(0,0,0,.1);
	    -moz-box-sizing: border-box;
		@include respond-to(res_lv1){margin:0px;}
		@include respond-to(res_lv2){}
		@include respond-to(res_lv3){}
		.box-video{
			@include respond-to(res_lv1){height:250px;}
			@include respond-to(res_lv2){height:360px;}	
			@include respond-to(res_lv3){height:450px;}								
			width:100%;
		}	  
		.box-label{
			@include respond-to(res_lv1){margin-bottom:10px;}
			font-family: Roboto, arial, sans-serif;
			font-size: 17px;
			padding-top:15px;
			padding-left:15px;
			font-weight:bold;
		}
		.box-title{
			font-size: 14px;
			border-bottom: 1px solid #ccc;
			padding-bottom:5px;
		}
	  .box-content{
		  padding:4px;
		  ul{
			  margin-bottom:15px;
			  li{
				  a{
					  font-size:12px;
				  }
			  }
		  }
	  }
		.infolist a{
			font-size:12px;
		}
		
		.sideContent{
			
			ul li{
				height:150px;
				margin-top:10px;
				padding:0px;
				background-position: 50% 50%;
		    /* background-size: cover; */
		    background-repeat: no-repeat;
		    position: relative;
		    
		    @include respond-to(res_lv1){max-width:300px;margin: 0 auto 0;}
				a{
					transition: all 0.5s ease;
			    display: block;
			    width: 100%;
			    height: 100%;
					.postTitle{
						padding: 10px;
				    color: #2d2d2d;
				    position: absolute;
				    width: 100%;
				    bottom: 0px;
				    font-weight: bold;
					}				    
			    
				}
				a:hover{
			    display: block;
			    width: 100%;
			    height: 100%;
			    background-color: black;
			    opacity: 0.4;	
			    
					.postTitle{
				    color: white;
					}				    
			    			    
				}					
		    .bg{
						display: block;
				    height: 41px;
				    width: 100%;
				    background-color: white;
				    opacity: 0.4;
				    filter: alpha(opacity=14);
				    position: absolute;
				    bottom: 0px;
				}
			}



			
		}  
  }
  
  .box#profileBox{
		padding:0px;
		padding-bottom:10px;			
		#profileCover{
			background-size: cover;
			height:75px;
			position:relative;
		}
		#profileImg{
			position:relative;
			margin:0 auto 0;
			width:45px;
			bottom:-50px;
			border: 1px solid #ccc;
			img{
				width:100%;
			}
		}
		#profileName{
			text-align: center;
			font-size:13px;
			margin-top:30px;
		}
		#profileState{
			ul{
				margin-top:10px;
				li{
					width:33%;
					text-align: center;
					margin:0px;
					.scoreTitle{
						font-size:11px;
						font-weight:bold;
					}
					.scoreNum{
						font-size:15px;
						line-height:33px; 
					}
				}
			}
			
		}
  }
  
  .adBox.box{
	  padding:4px;
	  min-height:90px;
  }


	#leftContainer{
		width:$leftContentWidth;
	  @include respond-to(res_lv1) { display:none; }
	  @include respond-to(res_lv2) { width:210px; }
	  @include respond-to(res_lv3) {}			
		
	}
	#centerContainer.fl{
		@include respond-to(res_lv1) { float:none; }
	}
	
	#centerContainer{
		width:$centerContainerWidth;
		margin:0px 8px;
	  @include respond-to(res_lv1) { width:inherit; }
	  @include respond-to(res_lv2) { 
		  width:520px;
			margin: 0px 5px;
		}
	  @include respond-to(res_lv3) {   }
		
		#shareBox{
			.paragraph:first-child{
				margin-top:0px;
			}
			.paragraph{
				width:100%;
				margin-top:8px;
				.input-group-addon{
					padding: 0px 8px;
					height: inherit;
					background-color: #f5f5f5;
					border: 0px;
					i{
						color: #747474;
					}
				}
				.removeParagraph, .moveParagraph{
					cursor: pointer;
				}
				.textParagraph{
					transition: all 0.5s ease;
					//border:1px solid #e2e2e2;
					min-height:25px;
					padding:0px 8px;
				}
				.textParagraph:focus{
					//border:1px solid #a6a6a6;
					padding:8px;
				}					
				
				
			}		


			#addContent{
				margin:10px 0px;
				border:1px solid #e2e2e2;
					a{
						transition: all 0.5s ease;
						display:block;
						cursor: pointer;
						text-decoration: none;
						text-align: center;
						padding:5px;
						color:#ccc;

					}
					a:hover{
						color:#747474;
					}
					#submitTale{border:0px;}					
				
			}

		}/* END ShareBox */
				
		
			/* PostLst Part */
		.postLst{		
				ul li{
					/*
					margin-top:8px;
					box-sizing: border-box;
			    border-radius: 3px;
			    background-color:white;
			    border: 1px solid #e3e3e3;
			    */
			}
			.boxImg{
				overflow:hidden;
					max-height:300px;					
				text-align: center;
				display:block;
				img{
					max-width:100%;
				}
			}
			.lst_title{
					font-weight:bold;
					padding:4px;
					font-size:15px;
			}
			.lst_content{
					padding:8px 8px 4px 8px;
					color:#373737;
					font-size:12px;
			}
			.lst_tags{
				color:#c8c8c8;
				padding:4px 8px 8px 8px;
				font-size:10px;
			}
		}		

	}/* END centerContainer */	
	
	#rightContainer{
		width:$rightContentWidth;
		@include respond-to(res_lv1) { display:none; }
		@include respond-to(res_lv2) { display:none; }
		@include respond-to(res_lv3) {   }			
	}

	/* ERROR  */
	.errorBox{
		text-align: center;
		margin:10% 0;
		font-weight:bold;
	}
	.errorMsg{
	    font-weight: bold;
	    font-size: 12px;
	    margin: 15px;
	    text-align: left;
	    color:#c42332;
	}
	
	/* ADs */
	.sideAdBox{
		padding:4px !important;
		margin:0 auto 0;
		
	}

	#post-info-left{
		float:left;
		width:69%;
		@include respond-to(res_lv1) { width:100%; }
		@include respond-to(res_lv2) { width:100%; }
		@include respond-to(res_lv3) {
			float:left;
			width:69%;
		}			
	}
	
	#post-info-right{
		@include respond-to(res_lv1) { display:none; }
		@include respond-to(res_lv2) { display:none; }
		@include respond-to(res_lv3) { }		
		float:right;
		width:30%;
		min-height:650px;	
	}
	#btn-open-desc{
		text-align: center;
		padding:10px;
		cursor:pointer;
		margin-top:-10px;
		
		color:#717171;
		
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ededed+0,e0e0e0+100&0+0,0.9+100 */
		background: -moz-linear-gradient(top, rgba(237,237,237,0) 0%, rgba(224,224,224,0.9) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(237,237,237,0) 0%,rgba(224,224,224,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(237,237,237,0) 0%,rgba(224,224,224,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ededed', endColorstr='#e6e0e0e0',GradientType=0 ); /* IE6-9 */
		&:hover{
			color:black;
		}
		
	}
	#post-desc-more{
		display:none;
	}
}// end content_wrapper
