.btn-choose{
	font-size:16px;
	cursor:pointer;
	font-weight:bold;
	padding: 4px 15px;
}

.btn-choose.selected{
	font-weight:bold;
}
.btn-feeling{
	color:#8f8f8f;
	cursor:pointer;
	width:50%;
	font-size:16px;
	text-align: left;
}

.btn-feeling#btn-feeling-1:hover, .btn-feeling#btn-feeling-1.activated{
	color:#6262c5;
}
.btn-feeling#btn-feeling-2:hover, .btn-feeling#btn-feeling-2.activated{
	color:#e94b4b;
}
#btn-post-share{
	cursor:pointer;
	color:#888888;
	i{
		font-size:18px;
		margin-right:4px;
	}
	
	&:hover{
		color:#232323;
	}
	
}
.btn-report{
	cursor:pointer;
	font-size:22px;
	i{
		color:#888888;
	}

	&:hover{
		i{
			color:#d25656;	
		}
		
	}
}

.btn-require-auth{
	cursor:pointer;
	
	i{
		color:#888888;
	}

}

.btn-more{
	cursor: pointer;
    width: 120px;
    text-align: center;
    margin: 0 auto 0;
	.btn-more-page{
		font-size:20px;
	}
	.btn-more-angle{
		font-size:25px;
		line-height: 0px;
		margin-top:5px;
	}
}
.btn-more:hover{
	cursor:pointer;
}

#btn-tabs{
	border-bottom:1px solid #ccc;
	.selected{
		color:white;
	}
}
.tab-panel{
	display:none;
	&.selected{
		display:block;
	}
}

#panel-file-upload{
	margin-top:25px;
	#uploadZone{
		min-height:200px;
		min-width:320px;
	}
	.dropzone{
	    border: 2px dashed #0087F7;
	    border-radius: 5px;
	    background: white;			
	}
}

.btn-default{
	background-color:white;
	&:hover, &:active, &:focus{
		background-color:#f0f2f5;	
	}

}
.open {
	.btn-default.dropdown-toggle{
		background-color:#f0f2f5;
		&:hover, &:focus{
			background-color:#f0f2f5;		
		}
	}
		
}