#video-wrapper{
		background-color:black;	
		position:relative;
		@include respond-to(res_lv3) { 
			width:100%; 
			//height:550px;
		}	
		@include respond-to(res_lv4) { 
			width:100%; 
			//height:600px;						
		}	
		@include respond-to(res_lv5) { 
			width:100%;
			//height:600px;		
		}
		.btn-mode-cimena, .btn-close-fixed-video{
			cursor:pointer;
		}
	#box-video{
			margin:0 auto 0;
			@include respond-to(res_lv1) { 
				width:100%; 
				height:200px;				
			}
			@include respond-to(res_lv2) { 
				width:100%; 
				height:300px;				
			}
			@include respond-to(res_lv3) { 
				width:100%;
				height:400px;				
			}	
			@include respond-to(res_lv4) { 
				width:993px;
				height:400px;				
			}	
			@include respond-to(res_lv5) { 
				width:993px;
				height:600px;
			}	
			
		.fix-util-bar, .fix-util-bar-hide{
			display:none;
			background-color:#484848;
			color:#ececec;
			font-size:20px;
			padding-top:3px;			
			padding-bottom:2px;
		}
		.fix-util-bar-hide{
			padding-top:0px;			
		}
		.box-video-player{
			height:100%;
			.video-box{
				height:100%;
			}
		}
	}
	#box-video.mode-cinema{
		position:fixed;
		margin:0 auto 0;
		z-index: 10001;
		width:90%;
		@include respond-to(res_lv1) { width:100%; }
		@include respond-to(res_lv2) { width:100%; }
		@include respond-to(res_lv3) { 
			width:100%; 
			height:550px;
			#commons_player {
			    height: 550px; 
			}	
		}	
		@include respond-to(res_lv4) { 
			width:100%; 
			height:600px;			
			#commons_player {
			    height: 600px; 
			}			
		}	
		@include respond-to(res_lv5) { 
			width:100%;
			height:600px;
			#commons_player {
			    height: 600px;
			}			
		}	
		.fix-util-bar{
			display:none;
		}	
	}	
		
	#box-video.mode-fixed{
		border:1px solid #ccc;
		@include respond-to(res_lv1) { width:320px; }
		@include respond-to(res_lv2) { width:320px; }
		@include respond-to(res_lv3) {
			position:fixed;
		    bottom: 100px;
		    right: 0px;
		    z-index: 9999;
		    width: 300px;
		    height: 200px;
			#commons_player {
			    height: 200px; 
			}		    		
		}	
		@include respond-to(res_lv4) {
			position:fixed;
		    bottom: 100px;
		    right: 0px;
		    z-index: 9999;
		    width: 300px;
		    height: 200px;
			#commons_player {
			    height: 200px; 
			}		    		
		}	
		@include respond-to(res_lv5) { 
			position:fixed;
		    bottom: 100px;
		    right: 0px;
		    z-index: 9999;
		    width: 400px;
		    height: 266px;
			#commons_player {
			    height: 266px;
			}		    	
		}		    
	    #commons_player{	    
	    }
		.fix-util-bar{
			display:block;
		}
	}
	#box-video.mode-fixed-hide{
		width:35px;
		.video-player {
		    display:none;
		}  

		.fix-util-bar{
			display:none;
		}
		.fix-util-bar-hide{
			display:block;
		}		
	}
	.share-box{
		position:absolute;
		right:20px;
		top:10px;
			@include respond-to(res_lv1) { display:none;}
			@include respond-to(res_lv2) { display:none; }
			@include respond-to(res_lv3) { display:none; }	
			@include respond-to(res_lv4) { }	
			@include respond-to(res_lv5) { }
		ul{
			li{

				color:#ccc;				
				font-size:40px;
				cursor:pointer;
			}
			li:hover{
				color:white;
				font-size:40px;
				cursor:pointer;
			}
			li.likeit{
				font-size:35px;
			}
			li:hover.likeit{
				color:#2d91e8;
			}			
			#more-share{
				ul{
					li{
					    color: #4d4d4d;
					    padding: 8px;
					    float: left;
					    font-size: 29px;
					    width: 49px;
					    text-align: center;
					    cursor:pointer;
					}
					li:hover{
						color:black;
					}
				}
			}
		}
	}
}
