#uploadZone{
	min-height:200px;
	min-width:320px;
	border: 2px dashed #0087F7;
	text-align: center;
	margin-top:25px;	
    .dz-message{
	    color:#5f5f5f;
	    .msg-1{
		    font-size:18px;
		    margin-bottom:20px;
	    }
	    .msg-2{
		    font-size:25px;
		    color:#868686;
	    }
	    .msg-3{
		    font-size:15px;
	    }
    }		
}

#file-manager-modal{ 
	#btn-tabs{
		border-bottom:1px solid #ccc;
		.selected{
			//color:white;
		}
	}
	.tab-panel{
		display:none;
		&.selected{
			display:block;
		}
	}
	
	#panel-file-upload{
		margin-top:25px;
		.dropzone{
		    border: 2px dashed #0087F7;
		    border-radius: 5px;
		    background: white;
		    .dz-message{
			    color:#5f5f5f;
			    .msg-1{
				    font-size:18px;
				    margin-bottom:20px;
			    }
			    .msg-2{
				    font-size:25px;
				    color:#868686;
			    }
			    .msg-3{
				    font-size:15px;
			    }
		    }		
		}
	}
	.content-list{
	}
	
	.panels{
		margin-top:15px;
		.box-file-list{
			margin-bottom:15px;
			margin-top:15px;
			cursor:pointer;
			&:hover{
				.list-cover-image{
					background-repeat: no-repeat;
					
					background-size: auto 120%;
					
				   -webkit-animation: fadeInFromNone 0.5s ease-out;
				    -moz-animation: fadeInFromNone 0.5s ease-out;
				    -o-animation: fadeInFromNone 0.5s ease-out;
				    animation: fadeInFromNone 0.5s ease-out;
				    
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
					filter: alpha(opacity=100);
					-moz-opacity: 1;
					-khtml-opacity: 1;
					opacity: 1;	
				}
				
				.list-cover-file{
					font-size:80px;
					
				    -webkit-animation: fadeInFromNone 0.5s ease-out;
				    -moz-animation: fadeInFromNone 0.5s ease-out;
				    -o-animation: fadeInFromNone 0.5s ease-out;
				    animation: fadeInFromNone 0.5s ease-out;
				    
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
					filter: alpha(opacity=100);
					-moz-opacity: 1;
					-khtml-opacity: 1;
					opacity: 1;
				}
				
			}
			
			.list-cover-image{
				background-repeat: no-repeat;
				
				width:100%;
				height:150px;
				background-size:cover;
				background-position: center center;
				border:1px solid #dddddd;

				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
				filter: alpha(opacity=70);
				-moz-opacity: 0.70;
				-khtml-opacity: 0.70;
				opacity: 0.70;	
				
				&:hover{
			    
				    
				    					
				}				
				
				
			}
			.list-cover-file{
				width:100%;
				height:110px;
				text-align: center;
				font-size:75px;
				
				-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
				filter: alpha(opacity=70);
				-moz-opacity: 0.70;
				-khtml-opacity: 0.70;
				opacity: 0.70;					
				
				&.pdf{
					color:#c83546;					
				}
				&.doc{
					color:#112fc5;
				}
			}
			
			.list-cover-text{
				white-space: nowrap;
				overflow: hidden;
				margin-top:10px;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
				-ms-text-overflow: ellipsis;				
			}
		}
		
		#panel-file-image{
			
		}
		
		#panel-file{
			
		}
		
		#panel-file-upload{
			
		}
		
	}
}

#file-manager-detail-modal{
	.list-cover-file{
	    width: 100%;
	    height: 110px;
	    text-align: center;
	    font-size: 75px;
		&.pdf{
			color:#c83546;					
		}
		&.doc{
			color:#112fc5;
		}	    
	}
	#file-preview{
		img{
			max-width:100%;
		}
		
	}
}

@-webkit-keyframes fadeInFromNone {
    0% {
        display: block;
        opacity: 0.75;        
    }

    1% {
        display: block;
        opacity: 0.75;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeInFromNone {
    0% {
        display: block;
        opacity: 0.75;
    }

    1% {
        display: block;
        opacity: 0.75;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeInFromNone {
    0% {
        display: block;
        opacity: 0.75;
    }

    1% {
        display: block;
        opacity: 0.75;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeInFromNone {
    0% {
        display: block;
        opacity: 0.75;
    }

    1% {
        display: block;
        opacity: 0.75;
    }

    100% {
        display: block;
        opacity: 1;
    }
}