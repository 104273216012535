/*
	$responsive-lv1: 708px; // No Padding
	$responsive-lv2: 816px; // padding: 0 30px 57px;
	$responsive-lv3: 1024px; // padding:0 30px 57px;
	$responsive-lv4: 1280px; // padding: 0 40px 0px;
*/

.container-2-row-main{

	float:right;
	margin-top:20px;
	    min-height: 100px;

		@include respond-to(res_lv1) { 
			width:98%;
			margin:1% auto;
			float:inherit;
		}
		@include respond-to(res_lv2) {
			width:64%;
		    margin-left: 1%;
		}
		@include respond-to(res_lv3) {
			width:69%;
		    margin-left: 1%;			
		}
		@include respond-to(res_lv4) {
		    width: 74%;
		    margin-left: 1%;
		}
		@include respond-to(res_lv5) { 
		    width: 74%;
		    margin-left: 1%;
		}			
}