.sidebar-block{
	margin-top:20px;
	.sidebar-top-bar{
		margin-bottom:18px;
		.sidebar-label{
			font-size:18px;
			font-weight:400;
			float:left;
			color:black;
		}
		.sidebar-more{
			float:right;
			font-size:13px;
			padding-top:5px;
			a{
				color:#b5b5b5;	
				&:hover{
					color:#7d7d7d;
				}
			}
			
		}
		margin-bottom:20px;
		
	}
}

.sidebar-search{
	#input-sidebar-search{
		@include respond-to(res_lv1){
			width:80%;
		}
		@include respond-to(res_lv2){

		}
		@include respond-to(res_lv3){
			width:70%;
			font-size:11px;
		}
		@include respond-to(res_lv4){
			width:82%;
		}
		@include respond-to(res_lv5){
			width:84%;			
		}
	}
	
	#btn-sidebar-search{
		@include respond-to(res_lv1){

		}
		@include respond-to(res_lv2){

		}
		@include respond-to(res_lv3){

		}
		@include respond-to(res_lv4){

		}
		@include respond-to(res_lv5){

		}	
	}
	
	
	.sidebar-search-box{
		padding:20px;
		background-color:#525559;
	}
	
	
	.btn-action-search{
	    padding: 8px 11px;
	    color: white;
	    background-color: #00a651;
	    font-size: 17px;
	}
}

.sidebar-banner{
	a{
		display:block;
		width:100%;
		height:100%;
		img{
			width:100%;
		}
	}
}
.sidebar-category{
	.sidebar-top-bar{
		border-bottom:1px solid #c0c0c0;
		padding-bottom:18px;
	}
	.sidebar-categories{
		margin-bottom:20px;
	}
	.sidebar-category-master{
		margin-right:18px;
		cursor:pointer;
		font-size:14px;
		color:#cccccc;
		@include respond-to(res_lv1){
			font-size:10px;
		}
		@include respond-to(res_lv2){
			font-size:10px;
		}
		@include respond-to(res_lv3){
			font-size:12px;
			margin-right:8px;
		}
		@include respond-to(res_lv4){

		}
		@include respond-to(res_lv5){

		}		
		
		span {
		    display: inline-block;
		    height: 15px;
		    overflow: hidden;
		    position: relative;
		    width: 15px;
		    i.fa-circle{
			    font-size:8px;
		    }
			i.fa-circle:before {
			    left: 0;
			    position: absolute;
			    top: 3px;
			}		    
		    
		}
	}
	.sidebar-category-master.active, .sidebar-category-master:hover{
		color:#4e6fac;
	}
	
	.sidebar-category-list{

		table {
			display:none;
		    border-collapse: collapse;
		    width:100%;
		    th, td{
			    width:33.33%;
				border: 1px solid #e6e6e6;
				display: table-cell;
				vertical-align: middle;				
				text-align: center;
		    }
		    td{
			    width:33.33%;
			    cursor:pointer;

				@include respond-to(res_lv3){
					height:76.68px;
					font-size:12px;
					padding: 14px;
				}
				@include respond-to(res_lv4){
					height: 89.88px;
				    font-size: 14px;
				        padding: 19px;
				}
				@include respond-to(res_lv5){
					height:99.89px;	
					font-size: 14px;
					padding: 21px;					
				}				    
			    &:hover{
				    background-color:#ebebeb;
			    }
			    &.empty{
				    background-color:#f4f4f4;
			    }
		    }
	
			&.active{
				display:table;
			}
		    
		}
		
	
	}
	
}
.sidebar-bbs{
	.sidebar-top-bar{
		border-bottom:1px solid #c0c0c0;
		padding-bottom:18px;
	}
	.sidebar-bbs-posts{
		ul{
			li{
				a{
					display:block;
					padding:5px;
				    white-space: nowrap;
				    overflow: hidden;
				    text-overflow: ellipsis;
				    -o-text-overflow: ellipsis;
				    -ms-text-overflow: ellipsis;					
				}
				a:hover{
					background-color:#f4f4f4;
				}
			}
		}		
	}

	
}