@import url(//fonts.googleapis.com/earlyaccess/nanummyeongjo.css);
@import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);
@import url(/_src/NanumSquare/nanumsquare.css);


// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$themeBaseColor: #3476b7;
$themeBaseFontColor: white;

$themeSpecialColor: #a62626;
$themeFontColor: #eaeaea;
$blockMargin:8px;
$borderColor: #ccc;
$boxBorderColor: #e3e3e3;
$boxOutlinerColor: #eaeaea;

$commonBorderColor: #e5e5e5;

$sideBar_bg:#1e1e1e;
$sideBar_color:#eaeaea;
$sideBar_child_color:#3f3f3f;

$sidePanelBg : #e3e3e3;
$sidePanelBg2 : #e3e3e3;
$sidePanelColor : #e3e3e3;
$sidePanelLine : #e3e3e3;

/***** Top Notice *****/
$topNoticeHeight: 0px;


/***** Header *****/
$headerWideHeight: 80px;
$headerNarrowHeight: 48px;
$headerBackgroundColor: white;
$headerColor: black;
$headerFontColor: $themeBaseFontColor;

/***** Content *****/
$leftContentWidth: 230px;
$rightContentWidth: 310px;
$centerContainerWidth: 583px;

$radius: 5px;

/***** FOOTER *****/
$footerBackgroundColor:#f3f3f5;
$footerHeight:121px;
$footerHeight_MAX:200px;

$pageArtHeight:300px;
$pageArtHeight-min:200px;


$pageCoverHeight:180px;

@mixin border-radius() {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

$responsive-lv1: 708px; // No Padding
$responsive-lv2: 816px; // padding: 0 30px 57px;
$responsive-lv3: 1024px; // padding:0 30px 57px;
$responsive-lv4: 1280px; // padding: 0 40px 0px;

$inner-padding-v3:30px;
$inner-padding-v4:40px;

@mixin respond-to($media) {
  @if $media == res_lv1 {
    @media only screen and (max-width: $responsive-lv1) { @content; }
  }
  @else if $media == res_lv2 {
    @media only screen and (min-width: $responsive-lv1 + 1) and (max-width: $responsive-lv2 - 1) { @content; }
  }
  @else if $media == res_lv3 {
    @media only screen and (min-width: $responsive-lv2 + 1) and (max-width: $responsive-lv3 - 1) { @content; }
  }
  @else if $media == res_lv4 {
    @media only screen and (min-width: $responsive-lv3 + 1) and (max-width: $responsive-lv4 - 1) { @content; }
  }
  @else if $media == res_lv5 {
    @media only screen and (min-width: $responsive-lv4) { @content; }
  }  
}
