#tpl_b{
	#main-middle-container{
		margin-top:20px;
		
		#main-row-1{
			width:68%;
			
			@include respond-to(res_lv1){
				width:100%;
			}
			@include respond-to(res_lv2){
				width:100%;
			}
			@include respond-to(res_lv3){
				width:68%;
			}
			@include respond-to(res_lv4){
				width:68%;
			}
			@include respond-to(res_lv5){
				width:72%;
			}				
			
		}
		#main-row-2{
			width:28%;		
			
			@include respond-to(res_lv1){
				width:28%;
				display:none;
			}
			@include respond-to(res_lv2){
				width:28%;
				display:none;
			}
			@include respond-to(res_lv3){
				width:28%;
			}
			@include respond-to(res_lv4){
				width:28%;
			}
			@include respond-to(res_lv5){
				width:25%;
			}			
				
		}		
		
	}
	
}