#block-manager{
	.tabs{
		border-bottom:1px solid #ccc;
		margin-bottom:20px;
	}
	.manager-label{
		font-size:16px;
		font-weight:bold;
		margin-bottom:20px;
	}
	.manager-sub-label{
		font-size:14px;
		color:#666666;
	}
	.manager-block{
		margin-top:20px;
		padding-bottom:20px;
		//border-bottom:1px solid #e6e6e6;
		&.no-line{
			border-bottom:0px;
		}
	}
	.box-primary-image{
		position: relative;
	    max-width: 320px;
	    border: 1px solid #dfdfdf;
		min-height: 171px; 
		.uiblock-primary-image{
		    width: 100%;
		    background-color: black;
		    height: 100%;
		    display: block;
		    position: absolute;
		    display: none;
		}
		#preview-post-type{
			position:absolute;
			left:0px;
			top:0px;
			padding:8px;
			background-color:#6e8eba;
			color:white;
		}
	}
	.btn-zone{
		position:relative;
		border-top:2px solid #d6d6d6;
		padding-top:20px;
		margin-top:10px;
		.btn{
			padding:12px 37px;			
		}
		#btn-del-post{
			position:absolute;
			background-color:#838391;
			color:white;
			right:0px;
			top:20px;
			&:hover{
				background-color:#e24b4b;
			}
		}
		#btn-cancel{
			&:hover{
				background-color:#dddddd;
			}	
		}
		.btn-zone-center{
			width:220px;
			margin:0 auto 0;
		}		
	}
	.radiobox-custom{
		cursor:pointer;
		&:hover{
			//background-color:#ecebeb;
		}
		
		.radio_or_checkbox{
			width:20px;
			height:20px;
		}
		.checkator_holder{
		    .checkator_element{
			    margin-top: 0px !important;
			    margin-bottom: 0px !important;
			    &:after, &:focus{
				    background-color:#0d84ea;
			    }
		    }		
		}
		
		
	}
	
	#btn-selectContentType{
		padding:20px;
		text-align: center;
		margin-top:10px;
		color:#a1abe0;
		border:1px #a1abe0 dashed;
		cursor:pointer;
		&:hover{
			color:#6877c5;
			border:1px #6877c5 dashed;			
		}
	}
	.attached-files li, .owners, .tags{
		li{
			float:left;
			display:block;
			background-color:white;
			margin:5px;
			border:1px solid #dfdfdf;
		}
		.boxLabel{
			padding:6px;
		}
		
		.tagLabel{
		    padding: 4px 10px;
		    font-size: 13px;
		    text-align: center;	
		}		
	
		.remove{
			padding:5px;
			font-size:12px;
			background-color:#d0d0ff;
			color:#919191;
			cursor:pointer;
			&:hover{
				background-color:#6a6ccb;
				color:white;
			}
		}
		.leftBox{
			float:left;
		}
	}
	#publish-state-set-2{
		border-top: 1px solid #ccc;
		margin-top: 8px;
		padding-top: 8px;
	}
	
	#manager-category{
		.category-block{
			margin-top:20px;
			padding-top:20px;
			border-top:1px solid #e6e6e6;
			&.block-first{
				padding-top:0px;
				margin-top:0px;
				border-top:0px;
			}
		}
	}
	
}
#selectContentType-modal{

	.btn-openContentModal{
		height:150px;
		padding:10px;
		padding-top:30px;
		text-align: center;
		.provider-log{
			width:180px;
			padding:20px;			
		}
		&:hover{
			border:2px solid #646b81;			
			.provider-log{
				padding:10px;
			}
		}
	}
}

#content-preview-modal{
	.preview-type{
		font-size:16px;
		margin-bottom:14px;
	}
	.preview-name{
		font-size:20px;
		margin-bottom:14px;
	}
	.preview-image{
		text-align: center;
		img{
			width:100%;
		}
	}
}

.content-modal{
	.btns{
		padding:8px;
		color:#8a8a8a;
		//cursor:pointer;
		&:hover{
			color:#4c4c4c;
		}
	}
}

.list-box-contents{
	margin-top:20px;
	.box-content{
		padding:10px;
		border:1px solid #ccc;
		background-color:white;
	}
	.list-handle{
	    font-size: 20px;
	    padding: 10px 0px;
	    cursor:pointer;
	    color:#787878;
    }
    .ui-sortable-helper .list-handle{
	    color:#232323;
	}
	.list-image{
		width:10%;
		position:relative;
		margin-left:10px;
		img{
			
			width:100%;
		}	
	}
	.list-title{
		width:50%;
		margin-left:10px;		
	}	
}