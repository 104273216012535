#tpl_b{
	background-color:#fafafa !important;
	#container{
		min-height: 100%;
	    padding-bottom:$footerHeight + 30;
	    //background-color: white;
		background-color:#fafafa;
	    position: relative;
	    
		@include respond-to(res_lv1){
			padding-top:$headerNarrowHeight + 20;
			margin-left:0px !important;
			padding-bottom:$footerHeight_MAX + 30;
		}
		@include respond-to(res_lv2){
			padding-top:$headerWideHeight + 20;
			padding-bottom:$footerHeight_MAX + 30;
		}
		@include respond-to(res_lv3){
			padding-top:$headerWideHeight + 20;
			padding-bottom:$footerHeight_MAX;
		}
		@include respond-to(res_lv4){
			padding-top:$headerWideHeight + 20; 
		}
		@include respond-to(res_lv5){
			padding-top:$headerWideHeight + 20; 
		}	
	}
	

}