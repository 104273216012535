#block-main-bottom{
	.bottom-main-label{
		padding-top:60px;
		font-size:20px;
	}
	.bottom-label{
	    font-size: 19px;
	    margin-bottom: 19px;
	}
	.bottom_category{
		//margin-bottom:60px;
	}
	.botton-box-category{
		padding-top:60px;
	}	
	.bottom-category-list{
		overflow:hidden;
		height:91px;

		li{
			a{
				float:left;
				width:119px;
				text-align: center;
				padding:34px 0px;
				display:block;
				border:1px solid #e6e6e6;
				border-left:0px;		
			}
			a:hover{
				background-color:#91bdff;
			}
		}

		li:first-child{
			a{
				border-left:1px solid #e6e6e6;
			}
		}
		
		li:last-child{
			a{
				border-left:0px;
			}
		}		
		
	}
	.block-bottom-links{
		margin-top:60px;
	}
	
}