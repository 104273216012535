#t-line-recommends{
	position:relative;
	.t-recommend-content{
		float:left;	
		height:150px;
		width:29%;
		margin:2%;
		border:1px solid #e0e0e0;		
	}
}

.t-line{
	background-color:white;
	width:100%;
	margin-bottom:20px;
	padding:14px;
	border:1px solid #e0e0e0;
	.t-line-header{
		.t-line-userimage{
			width:35px;
		}
		.t-line-info-box{
			font-size:14px;
			margin-left:10px;
			.t-line-info{
				font-size:10px;
				margin-top:2px;
				color:#a0a0a0;
			}
		}
	}
	.t-line-body{
		padding:8px;
		font-size:14px;
	}
	.t-line-statistics{
		font-size:12px;		
		border-bottom:1px solid #e0e0e0;		
		margin-bottom:8px;
		padding-bottom:8px;
		padding-top:8px;
	}
	
	.t-line-actions{
		li{
			float:left;
			margin-right:10px;
			font-size:12px;
		}
	}
}