#right-container{
	width:250px;
	padding:8px;
}

#right-sidebar{
	width:$leftContentWidth;
    height: 100%;
    top: 0;    
    position:fixed;
    background-color: white;
    z-index: 10001;
    overflow: hidden;
    right:-$leftContentWidth;
	@include respond-to(res_lv1) {  }
	@include respond-to(res_lv2) { }
	@include respond-to(res_lv3) { display:none; }	
	ul{
		li{
			a{
				display:block;
				padding:15px;	
			}
			a:hover{
				background-clor:#ccc;
			}
		}
	}
	.divider{
		border-bottom:1px solid #ccc;
	}
}