.bbs-name{
	font-size:14px;
	margin-bottom:12px;
}

.bbs-post-title{
	font-size:20px;
	border-bottom:1px solid #dfdfdf;
	font-weight:bold;
	padding-bottom:20px;
}

.bbs-post-content{
	min-height:250px;
	margin-top:10px;
	margin-bottom:10px;
	border-bottom:1px solid #dfdfdf;
}

.btn-bbs-list{

}