$userBlockBorder:#e6e6e6;
$userBlockBorder_2:#dadddf;

.page-userClass{
	.box-cover{
		position:relative;
		min-height:250px;
		margin-bottom:0px;
		background-color:white;
		
		.userInfo{
			position:absolute;
			left:130px;
			bottom:59px;
			font-size:14px;
		}

	}/* .box-cover */	

	
	#btns-userClass{
		border-left:1px solid #d2d2d2;		
		background-color:white;
		margin-left:150px;
		ul{
			li{
				border-right:1px solid #d2d2d2;				
				float:left;
				a{		
					display:block;
					padding:8px 40px;
				}
				a:hover{
					background-color:#dfe3ed;
				}
			}
			li.selected{
				a{
					background-color:#e6e8ed;
				}
			}
			li::after{
				clear:both;
			}		
		}
	
	}	
	
	
}

.block-sidebar{
	min-height:130px;
	width:23%;
	overflow:hidden;

	@include respond-to(res_lv1) { 
		
	}
	@include respond-to(res_lv2) {
		width:240px;		
	}
	@include respond-to(res_lv3) {
		width:240px;		
	}
	@include respond-to(res_lv4) { 
		width:240px;
	}
	@include respond-to(res_lv5) { 
		
	}	
}

.block-user{
	min-height:130px;
	overflow:hidden;
	background-color:white;

	@include respond-to(res_lv1) { 
		
	}
	@include respond-to(res_lv2) {
		width:240px;		
	}
	@include respond-to(res_lv3) {
		width:240px;		
	}
	@include respond-to(res_lv4) { 
		width:240px;
	}
	@include respond-to(res_lv5) { 
		
	}	
	
	.user-relationship{
		font-size:12px;
		width:100%;
		margin-top:9px;
		padding:0px 20px;
		a{
			width:50%;
			text-align: center;
			display:block;
			color:#808080;
		}
		a:hover{
			color:black;
		}
		.btn-follow{

		}
		.btn-follower{
			border-right:0px;	
		}
		.btn-relationship{
			border:1px solid #e6e6e6;
			padding:10px;
			cursor:pointer;
			&:hover{
				background-color:#e6e6e6;
			}
			&.btn-follower{
				border-right:0px;
			}
		}
	}
	.label-user-lv{
		margin-bottom:6px;
	}
	.btn-statistics{
		font-size:12px;
		
		a{
			color:#989898;	
		}
		a:hover{
			color:#606060;
		}
	}
	.bar-user-exp{
		height:10px;
		background-color:#e6e6e6;
		width:100%;
		margin-bottom:6px;
		position:relative;
		.bar-user-exp-current{
			width:10%;
			height:100%;
			background-color:#5275d0;
			float:left;
		}
		.bar-user-exp-remaining{
			width:10%;
			height:100%;	
			float:left;			
		}
	}
	
	.label-remaining-exp{
		font-size:12px;		
	}
	
	
	
	.userBlock-top{
		//height:230px;
		position:relative;
		
		@include respond-to(res_lv1) { 

		}
		@include respond-to(res_lv2) {
			//height:200px;
		}
		@include respond-to(res_lv3) {
			//height:200px;			
		}
		@include respond-to(res_lv4) { 
			//height:200px;				
		}
		@include respond-to(res_lv5) {
			
		}		
		
		.userBorder{
		    width: 100%;
		    height: 121px;
		    border-right: 1px solid $userBlockBorder;
		    border-left: 1px solid $userBlockBorder;
		}
		
		.editable{
			cursor:pointer;
		}
		&.with-desc{
			@include respond-to(res_lv1) { 
	
			}
			@include respond-to(res_lv2) {
				height:220px;
			}
			@include respond-to(res_lv3) {
				height:220px;			
			}
			@include respond-to(res_lv4) { 
				height:220px;				
			}
			@include respond-to(res_lv5) {
				height:250px;
			}		
		}
		
		
	}
	.userBlock-bottom{
		width:100%;
		//border-top:1px solid $userBlockBorder;
		.user-status{
			height:93px;
		    border-right: 1px solid $userBlockBorder;
		    border-left: 1px solid $userBlockBorder;
		    .user-apoint, .user-lv{
			    padding:0px 10px;
			    padding-top:17px;
		    }
		    .user-lv{
			    
		    }
		    .user-apoint{
			    
		    }
		    
		}
		.user-statistics-overview{
		    border-right: 1px solid $userBlockBorder;
		    border-left: 1px solid $userBlockBorder;
		    border-bottom:1px solid $userBlockBorder;
		    ul{
			    width:100%;
			    li{
				    float:left;
				    width:50%;
					border-top:1px solid $userBlockBorder_2;
					
					a{
				    	background-color:#f0f2f5;						
						display:block;
						padding:14px;
						
					}
					a:hover{
						background-color:#e9e9e9;
					}
				    &:first-child{
					    border-right:1px solid $userBlockBorder_2;
				    }
				    &:after{
					    clear:both;
					    content:" ";
					    display:block;
				    }
				    
				    .statistic-label{
					    color:#666666;
					    font-size:14px;
					    margin-bottom:10px;
				    }
				    .statistic-count{
					    font-size:18px;	    
				    }
				    
					.linkbox-add{
						 	font-size:12px;
						 	background-color:white;
						 	padding:6px;
						 	margin-top:8px;
						 	border:1px solid #dbdbdb;
						    color:#4f82b9;
						    cursor:pointer;
						    .add-label{
							    padding-top:2px;							    
						    }
						    .add-icon{
							    padding-top:2px;
							    font-size:14px;
						    }
						    i{
							    color:#959595;
						    }
						    &:hover{
							    background-color:#e9e9e9;
						    }
					 }				    
				    
			    }
		    }
		 	.btn-mycourses{
			 	display:block;
			    padding: 18px;
			    font-size: 17px;
			    text-decoration: none; 	
		 	}
		 	.btn-mycourses:hover{
			 	text-decoration: none;
			 	background-color:#f7f7f7;

		 	}

		}
		
	}
	
}


.userCover{
	position:relative;
	background-image: url('/_src/common/pageDefaultBackCover.png');
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 130px;
    
	@include respond-to(res_lv1) { 

	}
	@include respond-to(res_lv2) {
    	height: 100px;		
	}
	@include respond-to(res_lv3) {
	    height: 100px;		
	}
	@include respond-to(res_lv4) { 
		height: 100px;			
	}
	@include respond-to(res_lv5) {
		
	}    
    
}

#page-user-class{
	margin-top:10px;
	margin-bottom:30px;
	@include respond-to(res_lv1) { 
		margin-top:0px;
	}
	
	.user-page-title{
		font-size: 18px;
		margin-bottom: 16px;
	}
	
	#user-class-recommendation{
	    float: left;
	    min-height: 100px;

		@include respond-to(res_lv1) { 
			width:98%;
			margin:1% auto;
			float:inherit;
		}
		@include respond-to(res_lv2) {
			width:64%;
		    margin-left: 1%;
		}
		@include respond-to(res_lv3) {
			width:69%;
		    margin-left: 1%;			
		}
		@include respond-to(res_lv4) {
		    width: 74%;
		    margin-left: 1%;
		}
		@include respond-to(res_lv5) { 
		    width: 74%;
		    margin-left: 1%;
		}
		.recommendation-group{
			min-height:150px;
			margin-bottom:10px;
			.recommendation-group-label{
				font-size:15px;
				color:#353535;
			}
			.recommendation-boxes{
				.recommendation-box{
						
				}
			}

		}
	}
	
	.userInfo{
			@include respond-to(res_lv1) { 
		
			}
			@include respond-to(res_lv2) {
				position:absolute;
				bottom:25px;
				width:100%;
			}
			@include respond-to(res_lv3) {
				position:absolute;
				bottom:25px;
				width:100%;
			}
			@include respond-to(res_lv4) { 
				position:absolute;
				bottom:25px;
				width:100%;
			}
			@include respond-to(res_lv5) {
				position:absolute;
				bottom:15px;
				width:100%;
			}
			
		
			.userImage{
		
				width:100%;
				@include respond-to(res_lv1) { 
					position:absolute;
					bottom:120px;
				}
				@include respond-to(res_lv2) {
					
				}
				@include respond-to(res_lv3) {
					
				}
				@include respond-to(res_lv4) { 
						
				}
				@include respond-to(res_lv5) {
					
				}
				&.with-desc{
					@include respond-to(res_lv1) { 
						position:absolute;
						bottom:140px;
					}
					@include respond-to(res_lv2) {
						
					}
					@include respond-to(res_lv3) {
						
					}
					@include respond-to(res_lv4) { 
							
					}
					@include respond-to(res_lv5) {
						
					}
				}
				.userImage-wrapper{
					width: 95px;
					height: 95px;
					margin:0 auto 0;
					display:block;
					@include respond-to(res_lv1) { 
		
					}
					@include respond-to(res_lv2) {
						width: 75px;
						height: 75px;						
					}
					@include respond-to(res_lv3) {
						width: 75px;
						height: 75px;						
					}
					@include respond-to(res_lv4) { 
						width: 75px;						
						height: 75px;
					}
					@include respond-to(res_lv5) { 
						width: 95px;
						height: 95px;
					}
					.userblock-profile-image{
						width:100%;
						height:100%;
						background-position:center;
						background-size:cover;
					}
				}
				img{
					width: 95px;
					height: 95px;
					margin:0 auto 0;
					display:block;
					@include respond-to(res_lv1) { 
						width: 75px;
						height: 75px;		
					}
					@include respond-to(res_lv2) {
						width: 75px;
						height: 75px;
					}
					@include respond-to(res_lv3) {
						width: 75px;
						height: 75px;
					}
					@include respond-to(res_lv4) { 
						width: 75px;
						height: 75px;				
					}
					@include respond-to(res_lv5) { 
						width: 95px;
					}				
				}
				
			}
			
			.userName{
		
				text-align: center;
				width:100%;
				margin-top:7px;
			    font-weight: 400;		
				
				@include respond-to(res_lv1) {
		
					margin-top:30px;
				}
				@include respond-to(res_lv2) {
					font-size:14px;
				}
				@include respond-to(res_lv3) {
					font-size:18px;
				}
				@include respond-to(res_lv4) { 
					font-size:18px;
				}
				@include respond-to(res_lv5) { 
					font-size:20px;
				}		
				
				
			}//End userName
			.user-desc{
				text-align: center;
				font-size:12px;
				margin-top:4px;
				color:#474747;
			}
			.user-relationship{
				@include respond-to(res_lv1) {
				width:80%;
				margin:8px auto 0;
				}
				@include respond-to(res_lv2) {
		
				}
				@include respond-to(res_lv3) {
		
				}
				@include respond-to(res_lv4) { 
		
				}
				@include respond-to(res_lv5) { 
		
				}		
		
			}
			.user-narrow-btns{
				border-top:1px solid #e9e9e9;
				margin-top:8px;
				ul{
					li{
						float:left;
						width:25%;
						a{
							display:block;
							padding:8px;
							.statistic-label{
								text-align: center;						
								font-size:13px;
							}
							.statistic-count{
								font-size:11px;
								text-align: center;
							}
							&:hover{
								background-color:#f5f5f5;
							}	
						}
		
					}
				}
			}
		}//End UserInfo		
	
	#userClass-content{
		min-height:130px;
		width:73%;
		float:right;

	    
		@include respond-to(res_lv1) { 
			width:100%;
			float:inherit;
			margin-top:20px;
		}
		@include respond-to(res_lv2) {
			width:450px;
		}
		@include respond-to(res_lv3) {
			width:550px;			
		}
		@include respond-to(res_lv4) {

		}
		@include respond-to(res_lv5) { 

		}
		.userClass-selectBar{
			text-align: center;
			margin:0 auto 0;
			margin-bottom:35px;
			width:300px;
			
			@include respond-to(res_lv1) { 
				
			}
			@include respond-to(res_lv2) {
				
			}
			@include respond-to(res_lv3) {
				
			}
			@include respond-to(res_lv4) {
	
			}
			@include respond-to(res_lv5) { 
	
			}			
			
			a{
				display:block;
				color:#b3b3b3;
				padding:10px 20px;
				font-size:15px;
				
				@include respond-to(res_lv1) { 
					font-size:14px;
				}
				@include respond-to(res_lv2) {
					font-size:15px;
				}
				@include respond-to(res_lv3) {
					
				}
				@include respond-to(res_lv4) {
		
				}
				@include respond-to(res_lv5) { 
		
				}
				
				&:hover{
					color:#5380be;
				}
				&.selected{
					color:#5380be;
				}
				
				i{
					margin-right:4px;
				}
			}
			
			.divider{
			    border-right: 1px solid #ccc;
				height: 15px;
				margin-top: 14px;
			}
		}
		
		.userClass-list-content{
		    .userClass-course-list{
			    .box-course-list{
				    .list-cover{
					    display:block;
					    float:left;
						@include respond-to(res_lv1) { 
							width:48%;
							margin:1%;			
						}
						@include respond-to(res_lv2) {
							width:48%;
							margin:1%;
						}
						@include respond-to(res_lv3) {
							width:31%;
							margin:1%;
						}
						@include respond-to(res_lv4) {
							width:23%;
							margin:1%;
						}
						@include respond-to(res_lv5) { 
							width:23%;
							margin:1%;
						}						    
					    
					    .list-cover-image{
							position:relative;
						    margin-bottom:10px;
						    .list-cover-posts-count{
							    position:absolute;
							    height:100%;
							    width:30%;
							    background-color:black;
							    color:white;
							    text-align: center;
							    right:0;
							    padding-top:42px;
								opacity: 0.70;
								filter: alpha(opacity =70);							    
						    }
						    .list-cover-image-background{
							    width:100%;
						        height: 126px; 
							    background-size: cover;
							    background-repeat: no-repeat;
							    background-position: center center;							    
						    }
					    }
					    .list-info{
						    .list-title{
							    height:20px;
							    overflow: hidden;
						    }
					    }					    
				    }
			    }

		    }
		    
		}
		
		.box-class-search-input{
			width:85%;
			
			.input-class-search{
				border-top:1px solid #dedede;
				border-left:1px solid #dedede;
				border-bottom:1px solid #dedede;
				box-shadow:none;			
			}			
			
		}

			
		
		.box-user-list{
			float:left;			
			.list-cover{
				display:block;
				padding:8px;
				.list-cover-image{
					margin-bottom:8px;			
				}
				&:hover{
					background-color:#eaeaea;
				}
				.list-user-label{
					font-size:13px;
				}
			}
		}
		
		.box-board-list{
			.user-info{
				float:left;
				@include respond-to(res_lv1) { 
					width:10%;
					float:inherit;					
				}
				@include respond-to(res_lv2) {
					width:15%;
				}
				@include respond-to(res_lv3) {
					width:18%;
				}
				@include respond-to(res_lv4) {
					width:10%;
				}
				@include respond-to(res_lv5) { 
					width:7%;
				}					
			}
			.board-info{
				float:left;
				@include respond-to(res_lv1) { 
					width:88%;
					float:inherit;
				}
				@include respond-to(res_lv2) {
					width:78%;
				}
				@include respond-to(res_lv3) {
					width:65%;
				}
				@include respond-to(res_lv4) {
					width:65%;
				}
				@include respond-to(res_lv5) { 
					width:90%;
				}		
			}
			.list-cover{
				display:block;
				padding:10px;
				background-color: white;
				margin-bottom: 15px;
				border-bottom:1px solid #e0e0e0;
				&:hover{
					background-color:white;
				}		
			}
			.img-circle{
				width:50px;
				margin-right:10px;
	
			}
			.board-content{
				font-size:13px;
				padding:8px;
				border:1px solid #ddd;
				margin:4px;
				overflow:hidden;
			}
			.board-date{
				font-size:12px;
				color:#ddd;
			}
			
		}
		.box-list {
			.box-course-list{
				 .list-cover{
					 
					 
					 .list-info{
						 width:70%;
						 text-align: justify;
					 }
					 .list-info-2{
						 width:10%;
						 
					 	.btn{
						 	width:100%;
						 	a{
							 	color:white;
						 	}
					 	}
					 }					 
					 
					 &:hover{
						 background-color:white;
					 }
				 }
			}
		}
	}

	#userClass-timeline{
		min-height:130px;
		width:43%;
		float:left;
		margin:0px 1%;
		
		@include respond-to(res_lv1) { 
			width:100%;
			float:inherit;
		}
		@include respond-to(res_lv2) {
			width:450px;
		}
		@include respond-to(res_lv3) {
			width:550px;			
		}
		@include respond-to(res_lv4) {

		}
		@include respond-to(res_lv5) { 

		}
	}
	
	#userClass-sidebar{
		float:left;
		width:30%;
		background-color:white;
		padding:1%;
		border:1px solid #e0e0e0;
		
		@include respond-to(res_lv1) { 
			display:none;			
		}
		@include respond-to(res_lv2) {
			display:none;			
		}
		@include respond-to(res_lv3) {
			display:none;
		}
		@include respond-to(res_lv4) {

		}
		@include respond-to(res_lv5) { 
			
		}
	}
	#userClass-settings{
		.form-setting{
			position:relative;
			margin-top:15px;
			.setting-label{
				float:left;
				width:22%;
				padding-left:8%;
			}
			.setting-body{
				float:left;
				width:70%;
			}
		}
	}
	.sidebar-category{
		.sidebar-category-list{
	
			table {
				display:none;
			    border-collapse: collapse;
			    width:100%;
			    th, td{
				    width:33.33%;
					border: 1px solid #e6e6e6;
					display: table-cell;
					vertical-align: middle;				
					text-align: center;
			    }
			    td{
				    background-color:white;
				    width:33.33%;
				    cursor:pointer;
						height:76.68px;
						font-size:12px;
						padding: 14px;	
					@include respond-to(res_lv3){
						height:76.68px;
						font-size:12px;
						padding: 14px;
					}
					@include respond-to(res_lv4){
						height: 89.88px;
					    font-size: 13px;
					    padding: 15px;
					}
					@include respond-to(res_lv5){
						height:99.89px;	
						font-size: 14px;
						padding: 19px;
					}				    
				    &:hover{
					    background-color:#ebebeb;
				    }
				    &.empty{
					    background-color:#f4f4f4;
				    }
			    }
		
				&.active{
					display:table;
				}
			    
			}
			
		
		}
		
	}
	
}/* End page-user-class */
