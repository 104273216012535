#left-container{
	width:23%;
	float:left;
	z-index: 10001;
	margin-top:20px;
	@include respond-to(res_lv1) { display:none; }
	@include respond-to(res_lv2) { display:none; }
	@include respond-to(res_lv3) {}
	.menuLst:first-child{
		margin-top:0px;
		border-top:1px solid #e6e6e6;	
	}
	.menuLst{
		background-color:white;
		border-bottom: 1px solid #e6e6e6;
		border-left:1px solid #e6e6e6;
		border-right:1px solid #e6e6e6;			
		.boxTitle{
			//@include round-top();
	      	font-size: 13px;
		    padding:12px 15px;
		    background-color:white;
			display:block;
			&:hover, &.selected, &.activated{
				background-color:#474e59;	
				color:white;
			}
		}

		.subLst{
			display:none;
			background-color:#f0f2f5;
			border-bottom:1px solid #e6e6e6;
			&.selected{
				display:block;
			}
		}

		ul{

			  li{
				  a{
					  font-size:14px;
					  padding:15px;
					  display:block;
				  }
				  a:hover{
						color:#005baa;
				  }					  
				  a.selected{
						color:#005baa;
						font-weight:bold;
				  }				  
			  }
		  }		
	}
}

#left-sidebar{
	width:$leftContentWidth;
    height: 100%;  
    top: 0;    
    position:fixed;
    background-color: #222d32;
    z-index: 10001;
    overflow: hidden;
    left:-$leftContentWidth;
	@include respond-to(res_lv1) {  }
	@include respond-to(res_lv2) { }
	@include respond-to(res_lv3) { display:none; }

    font-size: 12px;
    -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
	.left-siderbar-header{
	    height: $headerNarrowHeight;		
		width:100%;
		padding:11px;
		#sidebar-header-logo{
		    background-size: contain;
		    width: 110px;
		    height: 32px;
		    display: inline-block;
		    margin: 0px auto 0;
		    background-repeat: no-repeat;
		    position: relative;
		}
	}



	/* .left-content */
	
	.left-content{
		overflow-y: auto;
		.navi{
			
			.parentNavi{
			    border-top: 1px solid #37414b;
			    border-bottom: 1px solid #1f262d;			
			    > a{
				    color:#939393;
					padding:10px;	    
				    display:block;
				    border-left: 3px solid transparent;
				    
				    .touch &:hover{
	
				    }
				    .no-touch &:hover{
					    color:white;
					    background-color:#8d8d8d; 
				    }		    
			    }
	
			    > a.selected{
				    color:white;
			    }
				
			}
			.childNavi{
			    background-color: #2c3b41;
			    overflow: hidden;
				display:none;
				
				.dropdown-header{
					overflow:hidden; 
					text-overflow: ellipsis;
					white-space: nowrap;
					margin:0 15px;		
					padding: 3px 0px;	
				}
			    a{
					padding: 10px;
					padding-left: 30px;
					display: block;
					color: #8b8b8b;
					cursor: pointer;
			    }
			    a:hover{
				    background-color: #222d32;
				    color:white;
			    }
				a.selected{
					color:white;
					font-weight:bold;
				}
			}
			.childNavi.selected{
				display:block;
			}	
		}
	
	
	
	}
	
}


