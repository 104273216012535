#tpl_c{
	#header{
		//height:180px;
		
		.wide{
			height:$headerWideHeight;
			#btn-master-category{
				float:none;
				
			}			
		}
		
	}

	
}