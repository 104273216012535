.bar-top{
	height:25px;
	background-color:#393939;
	color:#e7e7e7;
	a{
		color:#f0f0f0; 
		
	}
	a:hover{
		color:white;
	}
}

#top-notice{
	height:$topNoticeHeight;
	@include respond-to(res_lv1) { display:none;}  
	a{
		color:white;	
		display:block;
		font-size:12px;
		padding:6px;
	}
	
}
#top-notice.toggled-search-bar{
	z-index: 100001;
	position:relative;
}
#bar-search{
	@include respond-to(res_lv1) { top:48px;} 
	position:absolute;
	background-color:white;
	border-bottom:1px solid #e5e5e5;
	width:100%;
	transition: 1s;
	#search-keyword{
		background-color:black;
		color:white;
		display:none;
	}
	
	#bar-recommand-tags{

		width:100%;
		font-size:14px;
	    white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    -o-text-overflow: ellipsis;
	    -ms-text-overflow: ellipsis;
		display:none;
		
		@include respond-to(res_lv1) { box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1); } 
		@include respond-to(res_lv2) {  }
		@include respond-to(res_lv3) {  }
		#recommand-tags-label{
			padding:13px;
		}
		#main-tag-list{
			overflow: hidden;
		    height: 100%;
		    float: left;
		    padding: 0;
		    visibility:hidden;
			ul {
			    list-style: none;
			    display: block;
			    float: left;
			    width: 100%;
			    height: 100%;
			    padding: 0;
			    margin: 0;
			    padding: 1px 0px;
			    padding-top: 7px;		    
				li {
				    display: inline-block;/* changed */
					//width:80px;
				    text-align: center;
				    font-size: 15px;
				    a{
					    padding:6px 10px;
					    width:100%;
					    display:block;
				        overflow: hidden;
					    text-overflow: ellipsis;
					    -o-text-overflow: ellipsis;
					    -ms-text-overflow: ellipsis;
				    }
				    a:hover{
					    color:white;
				    }
				}			    
			    
			}			
			
		} 				

	}
	
}

#bar-search.toggled{
	@include respond-to(res_lv1) { top:48px;} 
	position:absolute;
	background-color:black;
	border-bottom:1px solid black;
	width:100%;
	transition: 1s;
	z-index:100001;
	#search-keyword{
		background-color:transparent !important;
		color:white;
		display:block;
	}
	
	#bar-recommand-tags{

		width:100%;
		padding:10px;
		font-size:14px;
		color:white;
		display:block;
		
		#main-tag-list{
			a{
				margin:0 10px;
				display:inline-block;
				color:white;
			}
		}
	}
	
}




#header{
	position: fixed;
    width: 100%;
    top: $topNoticeHeight;
    left: 0px;
    z-index: 990;
    //border-bottom:1px solid $commonBorderColor;
	@include respond-to(res_lv1) { top: 0px; }  

	.header-center{
		text-align: center;
		.header-logo{

			font-size:20px;
			a{

			}
		}
		.header-desc{
			
			font-size:20px;
	
		}			
	}
	
	#edition_code{
		margin-top:7px;
		padding:1px 5px;
		font-size:10px;
		cursor:pointer;
		color:#ccc;
		border:1px solid #ccc;
	}
	
	.wide{
		//height:$headerWideHeight;	
		box-sizing: border-box;
		border-bottom:1px solid $commonBorderColor;
		height:$headerWideHeight;
		
		@include respond-to(res_lv1){display:none;}
		@include respond-to(res_lv2){}
		@include respond-to(res_lv3){}
		.header-left-container{
			width:$leftContentWidth;
			overflow: hidden;
		}
		#wide-header-logo{ 
		    //background-image: url(/_src/univ_pack/logo.png);
		    background-size: contain;
		    width: 198px;
		    height: 49px;
		    display: inline-block;
			float:left;
		    background-repeat: no-repeat;
		    margin-top: 15px;
		    position: relative;
		    -ms-interpolation-mode:bicubic;
		}
		.logoBox{
			a{
				text-align: center;
				height:$headerWideHeight;
				display:block;
			}
			a:hover{
				color:black;				
			}
			
		}		
		.header-btn{
			a{
				color:$headerColor;
			}
		}
		#btn-master-category{
			float:left;
			text-align: center;
			//background-color:white;
			font-size:25px;
			margin-right:10px;
			margin-left:20px;
			height:65px; 
			.category{
				position:absolute;
				margin-top:0px;
				.dropdown-menu{
					width:180px;
					//margin-left:-55px;
					background-color:#333333;
					-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
					
					filter: alpha(opacity=90);
					-moz-opacity: 0.90;
					-khtml-opacity: 0.90;
					opacity: 0.90;				
					
					li{
						a{
							white-space: nowrap;
						    overflow: hidden;
						    text-overflow: ellipsis;
						    -o-text-overflow: ellipsis;
						    -ms-text-overflow: ellipsis;
						}
					}											
					
					.dropdown-submenu{
						position: relative;
						.dropdown-menu {
						    top: 0;
						    left: 177px;
						    margin-top: -1px;
							li a{
								@include round-none
							}						    
						}	
						.dropdown-menu::before{
							border:0px;
						}					
					}
					.dropdown-submenu:hover > .dropdown-menu {
					    display: block;
					    margin-top: 0;
					 }
					
				}

				.round-semi{
					li:first-child a{
						@include round-top
					}
					li:last-child a{
						@include round-bottom
					}						
				}

				.dropdown-menu::before{
					/*
					position: absolute;
				    top: -7px;
				    left: 80px;
				    display: inline-block;
				    border-right: 7px solid transparent;
				    border-bottom: 7px solid #333;
				    border-left: 7px solid transparent;
				    content: '';
				    */
				}
				
				.dropdown:hover > .dropdown-menu {
				    display: block;
				    margin-top: 0;

				 }
				.dropdown-menu a{
					color:white;
					padding:13px 20px;
				}
			}
			.btn-navi{
				padding:10px;
				font-size:18px;
				cursor:pointer;
				.dropdown-menu{
					width:180px;
				}
				.navi-sub{
					display:none;
					background-color:white;
					ul{
						li{
							float:left;
							padding:10px;
							cursor:pointer;
							a{
								color:#5c5c5c;
							}
							a:hover{
								color:black;
							}
						}
					}
				}				
				
			}
		
			.btn-navi.selected{
				
			}
		}



		.header-wide-userbox{
			.me{
				color:$themeBaseFontColor;
				margin-right:10px;
			}
			a{
				padding:10px;
				display:block;
			}
			a:hover{
				
			}
			.dropdown-menu > li > a:hover{
				background-color: $themeBaseColor !important;
			}
			
		}

		.header-wide-userbox{	
			> a{
				text-align: center;

				font-size:18px;
				display:block;
				color:$themeBaseFontColor;
			}
			a:hover{
				
			}
		}
		
		.header-right-container{
			-webkit-transition: margin-left .3s ease-in-out;
			-o-transition: margin-left .3s ease-in-out;
			transition: margin-left .3s ease-in-out;		
			> ul{
				 > li{
					position:relative;
					font-size:19px;
					cursor:pointer;			
				}				
			}
			#header-wide-left-btn{
				font-size:20px;
				padding:9px 18px;
				font-family: xeicon;
		
			}
			#header-wide-right-btn{
				font-size:20px;
				padding:9px 18px;
				font-family: xeicon;							
			}
		}
		.utils{
			position:absolute;
			right:39px;
			top:0px;
			z-index: 99;
			i{
				font-size:25px;
				cursor:pointer;
			}	
			#btn-show-login{
				padding-bottom:15px;

				.dropdown-menu:before {
				  position: absolute;
				  top: -7px;
				  right: 0px;
				  display: inline-block;
				  border-right: 7px solid transparent;
				  border-bottom: 7px solid #ccc;
				  border-left: 7px solid transparent;
				  border-bottom-color: rgba(0, 0, 0, 0.2);
				  content: '';
				}
				
				.dropdown-menu:after {
				  position: absolute;
				  top: -6px;
				  right: 1px;
				  display: inline-block;
				  border-right: 6px solid transparent;
				  border-bottom: 6px solid #ffffff;
				  border-left: 6px solid transparent;
				  content: '';
				}
				
			}
			#btn-show-search{
				
			}
			.header-profile-image{
				height:30px;
				width:30px;
				background-size:cover;
				background-position:center;
			}
			.header-profile-username{
				margin-top:5px;
			}
		}		
	}//.wide
	
	.narrow{
	    height: $headerNarrowHeight;
	    z-index: 998;
	    top: 0;
	    left: 0;
	    width: 100%;
	    min-width: 300px;
	    display:none;
		@include respond-to(res_lv1){
			display:block;
		}
		.header-profile-image{
			height: 30px;
			width: 30px;
			background-size: cover;
			background-position: center;			
		}
	    #narrow-header-logo{
		    //background-image: url(/_src/univ_pack/logo.png);
		    background-size: contain;
		    width: 145px;
		    height: 32px;
		    display: inline-block;
		    margin: 0px auto 0;
		    background-repeat: no-repeat;
		    position: relative;
		    margin-top: 9px; 
	    }
	    .header-btn{
		    position:absolute;
		    > a{
				display:block;
				font-size:23px;
				padding:5px 16px;
				cursor:pointer;		    
		    }
		    .btn-show-login{

			    > a{
				    font-size:18px;
			    }
		    }
	    }
		
		
		.btnSidebar{
			position: absolute;	
			padding: 11px 17px;
			cursor:pointer;
				i{
					font-size:17px;
					color:$themeBaseFontColor;
				} 
		}    
	/*
		#btn-left {
		    left: 5px;
		    top: 0px;
		    a{
				color:#484848;    
		    }
		    
		}
		#btn-right {
		    right: 0px;
		    top: 0px;
		    a{
				color:#484848;    
		    }
		}
		*/
		#btn-left{
			margin-top:5px;
		}
		.logoBox {
		    padding-top: 13px;
		    /* display: inline-block; */
		    text-align: center;
		    a{
				color:$themeBaseFontColor;    
		    }
		}
		.btn-login{	
			a{
				text-align: center;
				padding:10px;
				font-size:16px;
				display:block;
				color:$headerFontColor;
			}
			a:hover{
		
			}
		}
		.btn-show-login {
			color:white;
			i{
				margin-top:5px;	
			}
			
		}
		.utils{
			position:absolute;
			right:0px;
			top:0px;
			z-index: 99;
			padding: 7px 16px;
			color:#484848;
			> a{
				padding:0px;
			}
			i{
				font-size:25px;
				cursor:pointer;
			}	
			#btn-show-login{
				padding-bottom:15px;

				.dropdown-menu:before {
				  position: absolute;
				  top: -7px;
				  right: 0px;
				  display: inline-block;
				  border-right: 7px solid transparent;
				  border-bottom: 7px solid #ccc;
				  border-left: 7px solid transparent;
				  border-bottom-color: rgba(0, 0, 0, 0.2);
				  content: '';
				}
				
				.dropdown-menu:after {
				  position: absolute;
				  top: -6px;
				  right: 1px;
				  display: inline-block;
				  border-right: 6px solid transparent;
				  border-bottom: 6px solid #ffffff;
				  border-left: 6px solid transparent;
				  content: '';
				}	    
				
			}
			#btn-show-search{
				
			}		
		}			
	}

} // end header

#header.toggled-search-bar{
	z-index: 100001;
}