.box-comment{
	padding-bottom:20px;
	padding:8px 8px 20px;
}
.widget-area.blank {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-ms-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
}
body .no-padding {
	padding: 0;
}

.box-comment-text[contenteditable=true] {
	border-bottom:1px solid #ccc;
	resize:none;
	padding:4px;
	min-height: 30px;
}

.widget-area {
	background-color: #fff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
	-ms-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
	-o-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
	float: left;
	margin-top: 15px;
	padding: 25px 30px;
	position: relative;
	width: 100%;
}
.status-upload {
	background: none repeat scroll 0 0 #f5f5f5;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	float: left;
	width: 100%;
	form {
		width: 100%;
	}	
	textarea {
		display:block;
		background: none repeat scroll 0 0 #fff;
		border: medium none;
		color: #777777;
		font-size: 14px;
		height: 79px;
		letter-spacing: 0.3px;
		padding: 20px;
		width: 100%;
		resize: none;
		outline:none;
	}	
	ul {
		float: left;
		list-style: none outside none;
		margin: 0;
		padding: 0 0 0 15px;
		width: auto;
		> li {
			float: left;
		}
		> a {
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			-ms-border-radius: 4px;
			-o-border-radius: 4px;
			border-radius: 4px;
			color: #777777;
			float: left;
			font-size: 14px;
			height: 30px;
			line-height: 30px;
			margin: 10px 0 10px 10px;
			text-align: center;
			-webkit-transition: all 0.4s ease 0s;
			-moz-transition: all 0.4s ease 0s;
			-ms-transition: all 0.4s ease 0s;
			-o-transition: all 0.4s ease 0s;
			transition: all 0.4s ease 0s;
			width: 30px;
			cursor: pointer;
		}
		> a:hover {
			background: none repeat scroll 0 0 #606060;
			color: #fff;
		}
	}
	button {
		border: medium none;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		-ms-border-radius: 4px;
		-o-border-radius: 4px;
		border-radius: 4px;
		color: #fff;
		font-size: 14px;
		letter-spacing: 0.3px;
		padding: 6px 15px;
	}	
}

.dropdown > a > span.green:before {
	border-left-color: #2dcb73;
}

.status-upload{
	#btn-add-comment{
	    color: #fff;
	    background-color: #008cba;
	    border-color: #0079a1;	
	    padding: 0px 40px;	
	    &:hover{
			color: #fff;
			background-color: #006687;
			border-color: #004b63;		    
	    }
	}
}
.status-upload form button > i {
	margin-right: 7px;
}
.thumbnail {
    padding:0px;
}

#post-comment{
	margin-top:30px;
	padding:15px;	

	#box-input-comment{
		padding:20px;
		background-color:#edeff2;		
	}
	#box-comments{
		margin-top:20px;
	}
	.comment-user-image{
		padding-left:0px;
	}
}

.comment-user-image{
	padding:0px;
}
.panel {
	position:relative;
}
.panel>.panel-heading:after,.panel>.panel-heading:before{
	position:absolute;
	top:11px;left:-16px;
	right:100%;
	width:0;
	height:0;
	display:block;
	content:" ";
	border-color:transparent;
	border-style:solid solid outset;
	pointer-events:none;
}
.panel>.panel-heading:after{
	border-width:7px;
	border-right-color:#f7f7f7;
	margin-top:1px;
	margin-left:2px;
}
.panel>.panel-heading:before{
	border-right-color:#ddd;
	border-width:8px;
}

.box-comment{
	margin-left:30px;
}
.box-comment.level-0{
	margin-left:0px;
}

.btn-comment{
	cursor:pointer;
}

.btn-comment:hover{

}

.btn-response-comment{
	
}

.btn-del-comment{

}

.btn-modify-comment{

}
