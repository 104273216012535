$userBlockBorder:#e6e6e6;
$userBlockBorder_2:#dadddf;

.box-divider{
	color:#a9a9a9;
	padding:0px 10px;
	font-size:12px;
}
.box-created{
	color:#a9a9a9;
	font-weight:100;
	font-size:12px;		
}

.type-a{
	border-top:1px solid #e6e6e6;
	.box-divider{
		color:#a9a9a9;
		padding:0px 10px;
		font-size:12px;
	}
	.box-created{
		color:#a9a9a9;
		font-weight:100;
		font-size:12px;		
	}
	.btn-course-more{
		display:block;
		border:1px solid #cccccc;
		font-size:11px;
		padding:7px 11px;
		color:#5d5d5d;
		@include border-radius
	}
	.btn-course-more:hover{
		color:black;
	}
	.postLst{
		.box-post-list{
			.post-list-cover{
				 .post-list-cover-image{
				 	-webkit-box-shadow: inset 0px 0px 50px rgba(0,0,0,0.1);
					-moz-box-shadow: inset 0px 0px 50px rgba(0,0,0,0.1);
					box-shadow: inset 0px 0px 50px rgba(0,0,0,0.1);
				 }
			}
		}
	}	
}

.type-b{
	.postLst{
		.box-post-list{
			margin:1% 0px;
			width:33%;			
			padding-left:1%;			
			padding-right:1%;
			height:auto !important;
			.post-course-title{
				text-align: center;
				margin-bottom:30px;
				margin-top:50px;
				font-weight:bold;
				font-size:18px;				
			}		
			.post-list-title{
				margin-bottom:30px;
				margin-top:50px;
				font-weight:bold;
				font-size:15px;
			}
			.box-divider{
				color:#a9a9a9;
				padding:0px 10px;
				font-size:12px;
			}
			.box-created{
				color:#a9a9a9;
				font-weight:100;
				font-size:12px;		
			}			
			.post-list-cover{
				position:relative;
				height:272px;
				 .post-list-cover-image{
					 height:180px;
				 }
			}
		}
		.box-post-list-0{
		}
		.box-post-list-1{		

			border-left: 1px solid #ccc;
		    border-right: 1px solid #ccc;			
		}
		.box-post-list-2{		
		}
	}
}

#block-main-bottom{
	padding-bottom:55px;
	padding-top:60px;
	border-top: 1px solid #e6e6e6;	
}

#block-bottom-links{
	.box-link{
		width:20%;
		.link-label{
			font-size:19px;
			margin-bottom:19px;
		}
		.link-list{
			a{
				display:block;
				font-size:13px;
				color:#999999;
				margin-bottom:16px;
			}
			
		}

		
	}
}

.type-c{
	height:210px;
	min-height:210px;
	border-top: 1px solid #e6e6e6;
	background-color:white;
	.main-title{
		font-size:16px;
		text-align:left;
		font-weight:bold;
		padding: 25px 0px 20px 0px;
	}
	.box-main-bbs-list{
		height:120px;
		overflow: hidden;
		@include respond-to(res_lv1) { width:48%; margin:1%; }
		@include respond-to(res_lv2) { width:32%; }
		@include respond-to(res_lv3) { width:23%; }		
		@include respond-to(res_lv4) { width:23%; }
		@include respond-to(res_lv5) { width:23%; }
				
		.bbs-name{
			a{
				font-weight:bold;
				font-size:15px;				
			}
		}
		.bbs-posts{
			ul{
				li{
					
				}
			}
		}
		
	}
}


.block-user-main{
	min-height:130px;
	width:100%;
	overflow:hidden;
	background-color:white;
	border:1px solid $userBlockBorder_2;
	@include respond-to(res_lv1) { 
		
	}
	@include respond-to(res_lv2) {

	}
	@include respond-to(res_lv3) {

	}
	@include respond-to(res_lv4) { 

	}
	@include respond-to(res_lv5) { 
		
	}	
	
	.userInfo{
		position:static !important;
	}

	
	.userCover{
		position:relative;
		background-image: url('/_src/common/pageDefaultBackCover.png');
	    background-position: center;
	    background-size: cover;
	    width: 100%;
	    height: 130px;
	    
		@include respond-to(res_lv1) { 
	
		}
		@include respond-to(res_lv2) {
	    	height: 100px;		
		}
		@include respond-to(res_lv3) {
		    height: 100px;		
		}
		@include respond-to(res_lv4) { 
			height: 100px;			
		}
		@include respond-to(res_lv5) {
			
		}    
	    
	}
	
	.userInfo{
		@include respond-to(res_lv1) { 
	
		}
		@include respond-to(res_lv2) {
			position:absolute;
			bottom:25px;
			width:100%;
		}
		@include respond-to(res_lv3) {
			position:absolute;
			bottom:25px;
			width:100%;
		}
		@include respond-to(res_lv4) { 
			position:absolute;
			bottom:25px;
			width:100%;
		}
		@include respond-to(res_lv5) {
			position:absolute;
			bottom:15px;
			width:100%;
		}
		
	
		.userImage{
			width:40%;
			@include respond-to(res_lv1) { 
				width:100%;
			}
			@include respond-to(res_lv2) {
				
			}
			@include respond-to(res_lv3) {
				
			}
			@include respond-to(res_lv4) { 
					
			}
			@include respond-to(res_lv5) {
				
			}
			
			.img-circle{
				border:2px solid #e8e8e8;
				width: 80px;
				height:80px;
				
				margin-left:40px;
				margin-top:24px;
				display:block;
				background-position:center;
				background-size:cover;
				
				
				@include respond-to(res_lv1) { 
					width: 47px;
					margin:20px auto 0px;		
				}
				@include respond-to(res_lv2) {
					width: 55px;
					margin-left:10px;											
				}
				@include respond-to(res_lv3) {
					width: 55px;
					margin-left:20px;						
				}
				@include respond-to(res_lv4) { 
					width: 65px;
					margin-left:30px;						
				}
				@include respond-to(res_lv5) { 
					width: 80px;
				}				
			}
			
		}
		.userInfo-detail{
			width:59%;
			float:left;
			margin-top: 19px;
			padding-left: 22px;
			@include respond-to(res_lv1) {
				margin-top:4px;
				padding:0px;
				text-align: center;
				width:100%;
			}
			@include respond-to(res_lv2) {
				padding-left: 10px;
			}			
			.userName{
		
				text-align: inherit;
				margin-top:3px;
			    font-weight: 400;
    	
				
				@include respond-to(res_lv1) {

				}
				@include respond-to(res_lv2) {
					font-size:14px;
				}
				@include respond-to(res_lv3) {
					font-size:14px;
				}
				@include respond-to(res_lv4) { 
					font-size:18px;
				}
				@include respond-to(res_lv5) { 
					font-size:20px;
				}		
				
				
			}//End userName

		}

		.user-relationship{
			@include respond-to(res_lv1) {
			width:80%;
			margin:8px auto 0;
			}
			@include respond-to(res_lv2) {
	
			}
			@include respond-to(res_lv3) {
	
			}
			@include respond-to(res_lv4) { 
	
			}
			@include respond-to(res_lv5) { 
	
			}		
	
		}
		.user-narrow-btns{
			border-top:1px solid #e9e9e9;
			margin-top:8px;
			ul{
				li{
					float:left;
					width:25%;
					a{
						display:block;
						padding:8px;
						.statistic-label{
							text-align: center;						
							font-size:13px;
						}
						.statistic-count{
							font-size:11px;
							text-align: center;
						}
						&:hover{
							background-color:#f5f5f5;
						}	
					}
	
				}
			}
		}
	}//End UserInfo
	
	.user-relationship{
		font-size:12px;
		width:100%;
		margin-top:9px;
		a{
			width:50%;
			display:block;
			color:#808080;
		}
		a:hover{
			color:black;
		}
		.btn-follow{

		}
		.btn-follower{
				
		}
	}
	.label-user-lv{
		margin-bottom:6px;
		font-size:14px;
	}
	.btn-statistics{
		font-size:12px;
		
		a{
			color:#989898;	
		}
		a:hover{
			color:#606060;
		}
	}
	.bar-user-exp{
		height:10px;
		background-color:#e6e6e6;
		width:100%;
		margin-bottom:6px;
		position:relative;
		.bar-user-exp-current{
			width:10%;
			height:100%;
			background-color:#5275d0;
			float:left;
		}
		.bar-user-exp-remaining{
			width:10%;
			height:100%;	
			float:left;			
		}
	}
	
	.label-remaining-exp{
		font-size:12px;		
	}
	
	
	
	.userBlock-top{
		height:130px;
		position:relative;
		float:left;
		width:25%;
		@include respond-to(res_lv1) { 
			width:30%;
		}
		@include respond-to(res_lv2) {

		}
		@include respond-to(res_lv3) {
		}
		@include respond-to(res_lv4) { 		
		}
		@include respond-to(res_lv5) {
			
		}		
		
		.userBorder{
		    width: 100%;
		    height: 101px;
		    border-right: 1px solid $userBlockBorder;
		    border-left: 1px solid $userBlockBorder;
		}
	}
	.userBlock-bottom{
		width:75%;
		//border-top:1px solid $userBlockBorder;
		float:left;
		@include respond-to(res_lv1) { 
			width:70%;
		}		
		
		.user-status{
			height:93px;
		    border-right: 1px solid $userBlockBorder;
		    border-left: 1px solid $userBlockBorder;
		    .user-apoint, .user-lv{
			    padding:0px 10px;
			    padding-top:17px;
		    }
		    .user-lv{
			    
		    }
		    .user-apoint{
			    
		    }
		    
		}
		.user-statistics-overview{
		    border-right: 1px solid $userBlockBorder;
		    border-left: 1px solid $userBlockBorder;
		    float:left;
		    width:80%;
				@include respond-to(res_lv1) {
					width:100%;
				}
				@include respond-to(res_lv2) {
					width:78%;	
				}
				@include respond-to(res_lv3) {
									
				}
				@include respond-to(res_lv4) { 

				}
				@include respond-to(res_lv5) { 

				}
		    ul{
			    li{
				    float:left;
					width:25%;
					//border-bottom:1px solid $userBlockBorder_2;
						
					@include respond-to(res_lv1) {
						width:50%;
					}
					@include respond-to(res_lv2) {
						width:33.3%;
					}
					@include respond-to(res_lv3) {
						width:25%;
					}
					@include respond-to(res_lv4) { 
	
					}
					@include respond-to(res_lv5) { 
	
					}						
					
					a{
				    	height:130px;			    					
				    	background-color:#f0f2f5;						
						display:block;
						padding:30px;
					    border-left:1px solid $userBlockBorder_2;	
						@include respond-to(res_lv1) {
							padding:29px 18px;
						}
					}
					a:hover{
						background-color:#e9e9e9;
					}
				    &:after{
					    clear:both;
					    content:" ";
					    display:block;
				    }
				    
				    .statistic-label{
					    color:#666666;
					    font-size:14px;
					    text-align:center;			    
				    }
				    .statistic-count{
					    margin-bottom:10px;					    
					    font-size:27px;
					    text-align:center;		    
				    }
				    
			    }
		    }
		 	.btn-mycourses{
			 	display:block;
			    padding: 18px;
			    font-size: 17px;
			    text-decoration: none; 	
		 	}
		 	.btn-mycourses:hover{
			 	text-decoration: none;
			 	background-color:#f7f7f7;
		 	} 
		}
		.userBlock-tail{
			width:20%;
				@include respond-to(res_lv1) {

				}
				@include respond-to(res_lv2) {
					width:22%;
				}
				@include respond-to(res_lv3) {

				}			
			
			
			.btn-tails{
				color:white;			
				display:block;
				text-align: center;
				padding:21px;
				font-size:17px;
				@include respond-to(res_lv1) {

				}
				@include respond-to(res_lv2) {
					padding:25px 21px;
					font-size:12px;
				}
				@include respond-to(res_lv3) {
					padding:23px;
					font-size:13px;		
				}
				@include respond-to(res_lv4) { 
					font-size:16px;
					padding:21px;		
				}
				@include respond-to(res_lv5) { 

				}					
				
			}
			.btn-main-block-add-content{
	
				background-color:#0aa958;
	
			}
			.btn-main-block-channel-manage{
				background-color:#2f4359;		
			}
		}
	
	}
}