#page-copyright-guide{

	.license-guide-container{
		margin-top:20px;
	}
	.license-guide-title{
		font-size:18px;
		cursor:pointer;
		padding:10px;
	}	
	
	.license-guide-box{
		background-color:#f2f4f7;
		float:left;
		margin:1%;
		width:23%;
		height:200px;		
		padding:20px;
		cursor:pointer;
		
		&:hover, &.selected{
			background-color:#d1d7e1;
		}
		
		@include respond-to(res_lv1){
			margin:2%;			
			width:46%;
			height:180px;			
		}
		@include respond-to(res_lv2){
			margin:2%;			
			width:46%;
			height:180px;	
		}
		@include respond-to(res_lv3){
			margin:1%;			
			width:31%;
			height:180px;	
		}
		@include respond-to(res_lv4){
			margin:1%;			
			width:23%;
			height:200px;	
		}
		@include respond-to(res_lv5){
			margin:1%;			
			width:23%;
			height:200px;				
		}
		
		
	}

	
	.license-guide-content{
		display:none;
		padding:20px;
		border:1px solid #dfdfdf;
		
	}
	

	
	
}

.copyrightGuide-detail-modal{
	.guide-modal-header{

		margin:40px;
		margin-bottom:0px;
		border-bottom:2px solid black;
		font-size:25px;
		font-weight:bold;
		padding-bottom:20px;
		
		.btn-guide-detail-modal-close{
			font-size:30px;
			position:absolute;
		    right: 35px;
		    top: 30px;
		    cursor:pointer;
		}
		.guide-title{
			width:70%;
		}
	}
	
	.guide-modal-content{

		padding:40px;
		.guide-icon{
			width:10%;
			color:white;
			@include respond-to(res_lv1){
				width:100%;
				margin-bottom:15px;
			}
			@include respond-to(res_lv2){
				width:100%;
				margin-bottom:15px;				
			}
			@include respond-to(res_lv3){				
			}
			@include respond-to(res_lv4){

			}
			@include respond-to(res_lv5){

			}			
		}
		
		.icon-question{
			background-color:#005baa;			
		}
		
		.icon-answer{
			background-color:#0aa958;			
		}
		
		.icon-question, .icon-answer{
			width:25px;
			height:25px;
			text-align: center;
			padding:2px;			
			-webkit-border-radius: 90px;
			-moz-border-radius: 90px;
			border-radius: 90px;			
		}
		
		
		.guide-question{
			margin-bottom:20px;

		}
		.guide-answer{

		}
		.guide-question, .guide-answer{
			width:80%;
			line-height:1.7em;
			text-align:justify; 			
			@include respond-to(res_lv1){
				width:100%;
			}
			@include respond-to(res_lv2){
				width:100%;
			}
			@include respond-to(res_lv3){		
			}
			@include respond-to(res_lv4){

			}
			@include respond-to(res_lv5){

			}	
		}
	}	
	
	
	.guide-modal-footer{
		border-top:#d9d9d9;
		background-color:#f2f2f2;
		border-top:1px solid #d9d9d9;
		width:100%;
		
		.btn-prev{
			border-right:1px solid #d9d9d9;

		}
		.btn-next{
		
		}
		
		.btn-prev, .btn-next{
			padding:25px 20px;
			font-size:18px;
			position:relative;
			height:94px;
			cursor:pointer;
			@include respond-to(res_lv1){
				font-size:24px;
			}
			@include respond-to(res_lv2){
				font-size:24px;
			}
			@include respond-to(res_lv3){		
				font-size:19px;
			}
			@include respond-to(res_lv4){
				font-size:20px;
			}
			@include respond-to(res_lv5){
				font-size:23px;	 		
			}			
		}
		
		.btn-arrow-navi{
			font-size:35px;
			position:absolute;
			padding: 0px 10px;
		}
		.btn-arrow-navi-left{
			left:0px;
		}		
		.btn-arrow-navi-right{
			right:0px;
		}
		.next-num, .prev-num{
			width:10%;
		}
		.next-title, .prev-title{
			width:80%;
			@include respond-to(res_lv1){
				display:none;
			}
			@include respond-to(res_lv2){
				display:none;
			}
			@include respond-to(res_lv3){		
				font-size:16px;
			}
			@include respond-to(res_lv4){
				font-size:18px;
			}
			@include respond-to(res_lv5){
				font-size:18px;	 		
			}						
		}
		
		.btn-navi-content{
			width:90%;
		
		}
		
	}
	
}