/* ====================== Footer ====================== */
#footer{
	background-color:$footerBackgroundColor;
	min-height:$footerHeight;
	border-top:1px solid #d8dbe1;
    position: absolute;;
	bottom:0;
	width:100%;
    margin-top:25px;
	padding-top:22px;
	
	#footer-logo-box{
	    width:100%;
	    text-align: center;
	}
	#footer-box-logo{		    
		#footer-logo{
		    //background-image: url(/_src/univ_pack/logo.png);
		    background-size: contain;
		    width: 200px;
		    height: 42px;
		    //margin-top:15px;
				
		    display: block;
		    background-repeat: no-repeat;
			@include opacity-90	    
		}
		
	}

	.links{
	    text-align: center;
	    padding:3px;
		li{
			text-align: center;
			font-size:12px;
			float:left;
			a{
				color:#808080;
			}
			a:hover{
				color:#454545;
			}
			.privacy{
				font-weight:bold;			
				color:#333333;
			}			
		}
	}
	.footer-middle{
		margin-bottom:15px;
	    height: 20px;
	    overflow:hidden;
		@include respond-to(res_lv1) { 
			padding:0px 10px;
		}
		@include respond-to(res_lv2) { 
			padding:0px 10px;
		}
		@include respond-to(res_lv3) { 
			padding:0px 10px;	
		}
		@include respond-to(res_lv4) {  }
		@include respond-to(res_lv5) {  }	    
	    
	    .links-social{
		    margin-top:7px;
		    li{
			    a{
				    font-size:15px;
			    }
		    }
	    } 
	    .wrapper-links{
		    height:20px;
		    position:relative;
	    }
	}

	.footer-bottom{
		@include respond-to(res_lv1) { 
			padding:10px;
			text-align: center;
		}
		@include respond-to(res_lv2) { 
			padding:10px;
			text-align: center;
		}
		@include respond-to(res_lv3) { 
		}
		@include respond-to(res_lv4) {  }
		@include respond-to(res_lv5) {  }
	}
	.footer-info{
		@include respond-to(res_lv1) { 
			display:block;
			text-align: center;
		}
		@include respond-to(res_lv2) { 
			display:block;
			text-align: center;
		}
		@include respond-to(res_lv3) {  }
		@include respond-to(res_lv4) {  }
		@include respond-to(res_lv5) {  }	    		
	}	
	#btn-family{
		color:#dadada;
		font-size:10px;
		margin-left:20px;
		cursor:pointer;
	}
	
}

.address{
	color:#656565;
	margin-bottom:4px;
	font-size:12px;
}
.copyright{
	font-size:11px;
	color:#656565;
	filter: alpha(opacity=70);
    -moz-opacity: 0.70;
    -khtml-opacity: 0.70;
    opacity: 0.70;		
}