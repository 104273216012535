.box-error{
	text-align: center;
	padding:10% 8px;
	font-size:20px;
}
.box-page-error{
	text-align: center;
	font-size:16px;
	border:1px solid #d9d9db;
	background-color:white;
	padding:70px;
	width:100%;
	&.margin{
		margin:10% 8px;		
	}
	.warning-icon{
		font-size:35px;
		color:#e66f85;
	}
	.warning-text{
		color:#4d4d4d;
	}
}
.box-loading{
	font-size:25px;
	text-align: center; 
	padding:20px;
}
.box-top-container{
	hr{
	    margin-top: 15px;
	    margin-bottom: 15px;
	    border: 0;
	    border-top: 1px solid black;
	}
	
	.page-label{
		font-size:25px;
	}	
	
	.page-info{
		margin-top:40px;
		background-color:#474e59;
		height:310px;
		position:relative;
		@include respond-to(res_lv1) { height:inherit;}		
		.page-primaryImage{
			height:310px;
			background-size:cover;
			background-position:center;
			width:60%;
			@include respond-to(res_lv1) { width:100%; margin:1%; }	
			.count-post{
				height:54px;
				width:54px;
				text-align: center;
				padding:15px 10px;
				color:white;
				background-color:#4aafea;
			}

		}

		.page-description{
			font-size:14px;
			padding:10px;
			text-align: left;
			width:40%;
			@include respond-to(res_lv1) { width:100%; margin:1%; }
			
		}
		
		.page-info-category{
			padding:20px;
			color:white;
			font-size:13px;
			ul{
				li{
					margin-bottom:10px;
					a{
						color:#b6b8bb;
						font-size:12px;
					}
				}
			}
		}
	}
	
	
}

.box-middle-container{
	padding-bottom:10px;
	overflow:hidden;
	.page-info-desc{
		padding-top:20px;
	}
}

.box-list{
	table{
		thead{
			background-color:#d6d6d6;
			tr{
				height:51px;				
				th{
					vertical-align: middle;
					text-align: center;
				}					
			}
		}
		tbody{
			tr{
				td{
					padding:15px;
					text-align: center;
					vertical-align: middle;
					font-size:15px;
					cursor:pointer;
					.post-preview-content{
						margin-left:20px;
						margin-top: 25px;
						vertical-align: middle;
					}			
					.post-primaryImage{
						width:128px;
						height:80px;
						background-position:center;
						background-size: cover;
						position:relative;
						.post-duration{
							background-color:black;
							color:white;
							position:absolute;
							right:0px;
							bottom:0px;
							padding:5px;
							font-size:13px;
							-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
							filter: alpha(opacity=75);
							-moz-opacity: 0.75;
							-khtml-opacity: 0.75;
							opacity: 0.75;							
						}
					}
				}
			}
			tr:hover{
				background-color:#e9e9e9;
			}			
		}
	}
	.box-course-list{
		position:relative;
		.btn-delete{
			padding:3px 6px;
			top:10px;
			right:0px;
			background-color:#ad0c0c;
			color:white;
			position:absolute;
			z-index:100;
			&:hover{
				background-color:#de0404;
				cursor:pointer;
			}
		}
		.list-cover{
			display:block;	
			padding-top:20px;
			border-bottom:1px solid #ccc;
			height:165px;
			overflow:hidden;

			.list-cover-image{ 
				width:25%;
				height:126px;	
				background-size:cover;
				margin:10px;
				margin-top:0px;
				background-repeat: no-repeat;
				background-position: center center;
				position:relative;
				.list-cover-posts-count {
				    position: absolute;
				    height: 100%;
				    width: 30%;
				    background-color: black;
				    color: white;
				    text-align: center;
				    right: 0;
				    padding-top: 42px;
				    opacity: 0.70;
				    filter: alpha(opacity=70);
				}				
				.list-cover-image-background{
					width: 100%;
					height: 126px;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;					
				}
			}
			.list-info{
				width:70%;
				@include respond-to(res_lv1) { width:67%}	
				.list-title{
					font-size:20px;
					font-weight:bold;
					a{
						color:#545454;
						&:hover{
							color:black;
						}
						
					}
				}
				.list-meta-info, .box-created{
					margin-bottom:20px;		
				    font-size:1.3rem;
				    color:#847d7d;								
				}
				.list-desc{
				    max-height: 37px;
				    overflow: hidden;
				    font-size:1.3rem;
				    color:#847d7d;
				}			
			}

		}
		.list-cover:hover{
			background-color:#cfcfcf;
		}

	}
	
	&.box-post{
		.box-course-list{
		
			.list-cover{
				display:block;	
				padding-top:20px;
				border-bottom:1px solid #ccc;
				height:117px;
				overflow:hidden;
				.list-cover-image{ 
					width:15%;
					height:80px;	
					background-size:cover;
					margin:10px;
					margin-top:0px;
					background-repeat: no-repeat;
					background-position: center center;
				}
				.list-info{
					width:80%;
					@include respond-to(res_lv1) { width:67%}
					.list-title{
						font-size:20px;
						font-weight:bold;
						margin-bottom:20px;

					}
					.box-created{
						color:#3c3c3c;
					}				
				}
	
			}
			.list-cover:hover{
				background-color:#cfcfcf;
			}
	
		}
	}
	
	.box-course-list-left{
		float:left;
		width:20%;
	    height: 176px;
	    overflow: hidden;		
		@include respond-to(res_lv1) { width:50%; }
		@include respond-to(res_lv2) { width:33%; }
		@include respond-to(res_lv3) { width:33%; }
		.list-cover{
			display:block;
			padding-top:10px;
			overflow:hidden;

			.list-cover-image{ 
				height:126px;	
				background-size:cover;
				margin:10px;
				margin-top:0px;
				background-repeat: no-repeat;
				background-position: center center;
			}
			.list-info{
				width:70%;
				@include respond-to(res_lv1) { width:67%}
				.list-title{
					padding:0px 12px;
					font-size:13px;
					font-weight:bold;
					margin-bottom:20px;
				}
				.box-created{
					color:#3c3c3c;
				}				
			}

		}
		.list-cover:hover{
			background-color:#cfcfcf;
		}

	}	
	.list-label{
		height:25px;
		.title{
			font-size:18px;
			font-weight:bold;
		}
		.more{
			padding-top:4px;
			font-size:12px;
			cursor:pointer;
		}
	}
}