.page-navi{
	padding:4px 0px;
	border-bottom:1px solid #d2d2d2;
	margin-bottom:10px;
	background-repeat: no-repeat;
	font-size:18px;
	border-bottom:1px solid #e5e5e5;	
	a,span{	
		font-size:14px;
	}
	
}
.page-info{

	.page-info-name{
		font-size:20px;
		font-weight:bold;
		margin-bottom:10px;
	}
	.page-info-desc{
		font-size:18px;
		font-size:15px;
	}	
}
