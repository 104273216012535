#post-audio{
	.post-header{
		text-align:center;
		height:300px;
		position:relative;
		.post-header-background{
			padding:10px;
			width:100%;
			height:100%;
			position:absolute;
			background-position: center center;
			background-repeat: no-repeat;
			background-size:cover;
		}
		.post-header-cover{
			height:100%;
			width:100%;
			height:100%;			
			position:absolute;
			background-color:black;
			opacity: 0.45;
			filter: alpha(opacity =45);			
		}
		.post-title-box{
			width:100%;	
			height:100%;	
			position:absolute;
			color:white;
			padding:20px;
			.post-title{
				font-size:30px;	
				margin-top:10%;
			}
			
			.post-desc{
				margin-top:10px;
				font-size:15px;			
			}
		}

		
	}
	.post-article{
		padding:20px;
		border-bottom:1px solid #dfdfdf;
		margin-bottom:10px;
	}	
}