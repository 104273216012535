.corverBorder{
	width: 100%;
	height: $pageCoverHeight;
	background-image: url('/_src/common/pageCover.png');
	background-position: bottom center;
	background-repeat: repeat-x;
	position: absolute;
	top: 0;
}


