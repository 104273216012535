#tpl_a{
	#header{
		height:180px;
		
		.wide{
			#btn-master-category{
				float:none;
				
			}			
		}
		
	}

	
}